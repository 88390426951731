body {
	font-size: 1rem;
	line-height: 1.75;
}

@media (--mq-up-to-small) {
	body {
		padding-top: 77px;
	}
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	body {
		padding-top: 124px;
	}
}

body.has-error {
	padding-top: 0;
}

ol:not([class]),
ul:not([class]) {
	list-style: none;
	margin: 0 0 1rem 0;
	padding-left: 1rem;
}

ol:not([class]) li,
ul:not([class]) li {
	line-height: 1.75;
}

li ol:not([class]),
li ul:not([class]) {
	margin-bottom: 0;
}

ol:not([class]) {
	counter-reset: ol-list-items;
	padding-left: 1.375rem;
}

ol:not([class]) ol:not([class]) {
	counter-reset: ol-sub-list-items;
	padding-left: 2.375rem;
}

li:not([class]) {
	line-height: 1.5;
	margin: 0;
	padding: 0;
	position: relative;
}

li:not([class]):last-child {
	margin: 0;
}

li:not([class])::before {
	position: absolute;
}

ul:not([class]) li:not([class])::before {
	background-color: black;
	border-radius: 0.25rem;
	content: "";
	display: block;
	top: 0.75rem;
	left: -0.875rem;
	width: 0.25rem;
	height: 0.25rem;
}

ol:not([class]) li:not([class])::before {
	color: black;
	content: counter(ol-list-items);
	counter-increment: ol-list-items;
	font-size: 1rem;
	font-weight: var(--font-weight-normal);
	line-height: 1.75;
	top: 0;
	left: -1.375rem;
}

ol:not([class]) ol:not([class]) li:not([class])::before {
	content: counter(ol-list-items) "." counter(ol-sub-list-items);
	counter-increment: ol-sub-list-items;
	left: -2.375rem;
}

img {
	display: block;
	max-width: 100%;
}

table,
th,
td {
	border: 1px solid black;
}

table {
	border-collapse: collapse;
	font-size: 1rem;
	margin: var(--space-x-small) 0;
	width: 100%;
	max-width: 100%;
}

@media (--mq-from-small) {
	table {
		margin-top: var(--space-small);
		margin-bottom: var(--space-small);
	}
}

table:first-child {
	margin-top: 0;
}

table:last-child {
	margin-bottom: 0;
}

th,
td {
	padding: 1rem;
	text-align: left;
}

@media (--mq-from-small) {
	th,
	td {
		padding: 1rem 1.875rem;
	}
}

th {
	font-weight: var(--font-weight-bold);
}

td {
	font-weight: var(--font-weight-normal);
}

dl {
	margin: 0 0 2rem 0;
}

dl:last-child {
	margin-bottom: 0;
}

dt {
	font-weight: var(--font-weight-bold);
}

dd {
	margin: 0;
	padding: 1rem 0;
}

hr {
	background-color: black;
	border: none;
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	height: 1px;
}

@media (--mq-from-small) {
	hr {
		margin-top: 1.75rem;
		margin-bottom: 1.75rem;
	}
}

@media (--mq-from-wide) {
	hr {
		margin-top: 2.25rem;
		margin-bottom: 2.25rem;
	}
}

.g-recaptcha {
	display: flex;
	padding: 1em 0;
	justify-content: flex-start;
	width: 100%;
}

@media (--mq-up-to-xsmall) {
	.g-recaptcha {
		transform: scale(0.85);
		transform-origin: 0 0;
	}
}
