.u-color-detailed--blue .calendar-meta,
.u-color-detailed--pink .calendar-meta {
	color: var(--color-blue);
}

.u-color-detailed--cyan .calendar-meta,
.u-color-detailed--red .calendar-meta,
.u-color-detailed--yellow .calendar-meta {
	color: var(--color-red);
}

.calendar-meta__row:not(:last-child) {
	margin-bottom: 1rem;
}

@media (--mq-from-small) {
	.calendar-meta__row {
		display: flex;
		align-items: flex-start;
	}
}

@media (--mq-from-small) {
	.calendar-meta__label {
		flex-basis: 130px;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (--mq-from-small) {
	.calendar-meta__value {
		/* stylelint-disable-next-line declaration-property-value-no-unknown -- broken, must be fixed by a human */
		flex-basis: basis;
		flex-grow: 1;
		flex-shrink: 1;
	}
}
