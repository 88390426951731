.site-header {
	position: relative;
}

@media (--mq-up-to-small) {
	.site-header {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		height: 78px;
	}
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.site-header {
		padding-top: var(--space-x-small);
		padding-bottom: var(--space-x-small);
		height: 124px;
	}
}

@media (--mq-up-to-medium) {
	.site-header {
		box-sizing: border-box;
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 9;
	}
}

@media (--mq-from-medium) {
	.site-header {
		padding-top: 7.5rem;
		padding-bottom: 7.5rem;
	}
}

@media (--mq-from-wide) {
	.site-header {
		padding-top: 8.75rem;
		padding-bottom: 8.75rem;
	}
}

@media (--mq-from-large) {
	.site-header {
		padding-top: 10rem;
		padding-bottom: 10rem;
	}
}

@media (--mq-up-to-medium) {
	#mobile-navigation-checkbox:checked ~ .site-header {
		background-color: var(--color-cyan);
	}
}

.l-styleguide .site-header {
	position: relative;
}

.site-header__wrapper {
	display: flex;
	align-items: center;
}

@media (--mq-up-to-medium) {
	.site-header__wrapper {
		justify-content: flex-end;
	}
}

@media (--mq-from-medium) {
	.site-header__wrapper {
		justify-content: space-between;
	}
}

.site-header__logo-wrapper {
	position: relative;
}

@media (--mq-up-to-medium) {
	.site-header__logo-wrapper {
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (--mq-up-to-small) {
	.site-header__logo-wrapper {
		flex-basis: calc(100% - 104px);
	}
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.site-header__logo-wrapper {
		flex-basis: calc(100% - 136px);
	}
}

.site-header__logo {
	display: block;
	margin-right: auto;
	margin-left: auto;
	width: 70px;
	height: 30px;
}

@media (--mq-from-small) {
	.site-header__logo {
		width: 140px;
		height: 60px;
	}
}

@media (--mq-from-medium) {
	.site-header__logo {
		width: 95px;
		height: 41px;
	}
}

@media (min-width: 1100px) {
	.site-header__logo {
		width: 140px;
		height: 60px;
	}
}

@media (--mq-from-large) {
	.site-header__logo {
		width: 164px;
		height: 70px;
	}
}

.site-header__logo span {
	display: none;
}

.site-header__logo__icon {
	display: block;
	transition: fill 96ms linear;
	width: 100%;
	height: 100%;
}

.site-header.u-colored--blue .site-header__logo__icon,
.site-header.u-colored--red .site-header__logo__icon {
	fill: white;
}

.site-header.u-colored--pink .site-header__logo__icon {
	fill: var(--color-blue);
}

.site-header.u-colored--cyan .site-header__logo__icon,
.site-header.u-colored--yellow .site-header__logo__icon {
	fill: var(--color-red);
}

@media (--mq-up-to-medium) {
	#mobile-navigation-checkbox:checked ~ .site-header .site-header__logo__icon {
		fill: var(--color-red);
	}
}

@media (--mq-from-medium) {
	.site-header.u-color-detailed--blue .site-header__logo__icon {
		fill: var(--color-blue);
	}
}

@media (--mq-from-medium) {
	.site-header.u-color-detailed--red .site-header__logo__icon {
		fill: var(--color-red);
	}
}

@media (--mq-from-medium) {
	.site-header__logo:hover .site-header__logo__icon {
		fill: var(--color-red);
	}
}

@media (--mq-from-medium) {
	.site-header.u-colored--cyan .site-header__logo:hover .site-header__logo__icon,
	.site-header.u-colored--red .site-header__logo:hover .site-header__logo__icon,
	.site-header.u-colored--yellow .site-header__logo:hover .site-header__logo__icon {
		fill: var(--color-blue);
	}
}

@media (--mq-up-to-medium) {
	.site-header__navigation-wrapper {
		display: flex;
		flex-grow: 0;
		flex-shrink: 0;
		overflow: hidden;
		justify-content: space-between;
		align-items: center;
	}
}

@media (--mq-up-to-small) {
	.site-header__navigation-wrapper {
		flex-basis: 52px;
	}
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.site-header__navigation-wrapper {
		flex-basis: 68px;
	}
}

@media (--mq-from-medium) {
	.site-header__menu-toggle {
		display: none;
	}
}

@media (--mq-up-to-medium) {
	.site-header__navigation {
		display: none;
	}
}

.site-header__meta {
	color: black !important;
	display: flex;
	font-size: 0.875rem;
	position: absolute;
	top: 1.25rem;
	right: 2.25rem;
}

@media (--mq-up-to-medium) {
	.site-header__meta {
		display: none;
	}
}

.site-header__meta__languages {
	margin-left: 2.25rem;
}

@media (--mq-from-wide) {
	.site-header__meta__languages {
		margin-left: 3rem;
	}
}

.site-header__meta__languages a:not([class]) {
	color: black !important;
}

/* #region Search mobile */
.site-header__search-mobile {
	color: currentColor;
	transition: color 96ms linear;
}

/* mq-from-medium */
@media (--mq-from-medium) {
	.site-header__search-mobile {
		display: none;
	}
}

.site-header__search-mobile .icon-search {
	color: currentColor;
	display: block;
	width: 15px;
	height: 15px;
}

/* mq-from-small */
@media (--mq-from-small) {
	.site-header__search-mobile .icon-search {
		width: 22px;
		height: 22px;
	}
}

#mobile-navigation-checkbox:checked ~ .site-header .site-header__search-mobile {
	color: var(--color-red);
}
/* #endregion Search mobile */
