.info-modal {
	display: block;
	width: 100%;
}

.info-modal__trigger {
	width: 100%;
}

.info-modal__title {
	color: var(--color-red);
	font-size: 1.375rem;
	font-weight: var(--font-weight-normal);
	line-height: 1.3333;
}

@media (--mq-from-small) {
	.info-modal__title {
		font-size: 2.5rem;
		line-height: 1.5;
	}
}

@media (--mq-from-wide) {
	.info-modal__title {
		font-size: 3.125rem;
	}
}

.u-color-detailed--blue .info-modal__title,
.u-color-detailed--pink .info-modal__title {
	color: var(--color-blue);
}
