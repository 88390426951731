/*
 * Filter Bar
 * ==========
 * This is the filter bar component, the main layout parts are:
 * [1] Filter-bar: used for general styling
 * [2] Row: Visually split up all content with a border
 * [3] Wrapper: Create padding and align it's content
 * [4] Left and Right: position items in vertical center
 */

.filter-bar {
	background-color: var(--color-red);
	color: white; /* [1] */
	width: 100%;
}

.filter-bar__row + .filter-bar__row {
	border-top: 1px solid var(--color-red-dark); /* [2] */
}

.filter-bar__wrapper {
	padding-top: 1.1875rem; /* [3] */
	padding-bottom: 1.1875rem;
}

@media (min-width: 1024px) {
	.filter-bar__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.filter-bar__wrapper.filter-bar__wrapper--wrap {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}
}

@media (min-width: 1024px) {
	.filter-bar__wrapper.filter-bar__wrapper--wrap {
		flex-wrap: wrap;
		padding-bottom: 0;
	}
}

.filter-bar__left,
.filter-bar__right {
	/* [4] */
}

@media (min-width: 768px) {
	.filter-bar__left,
	.filter-bar__right {
		display: flex;
		align-items: center;
	}
}

@media (min-width: 1024px) {
	.filter-bar__wrapper--wrap .filter-bar__left,
	.filter-bar__wrapper--wrap .filter-bar__right {
		margin-bottom: 1.1875rem;
	}
}

.filter-bar__left {
	margin-bottom: 1rem;
}

@media (min-width: 1024px) {
	.filter-bar__left {
		margin-bottom: 0;
		margin-left: 0.5rem;
	}
}

@media (min-width: 1024px) {
	.filter-bar__right {
		margin-left: 0.5rem;
	}
}

/*
 * Filter Bar: Toggle
 * ==================
 * The toggle is used to show and hide the filter form.
 * Purpose:
 * [1] Hide the toggle checkbox
 * [2] The toggle label needs to be white
 * [3] The toggle label has different copy when toggled
 * [4] When the toggle is active, change the background color
 */

.filter-bar__toggle {
	display: none; /* [1] */
}

.filter-bar__label-wrapper .filter-bar__label {
	color: var(--white); /* [2] */
	cursor: pointer;
}

.filter-bar__label-wrapper .filter-bar__label::before {
	content: attr(data-open); /* [3] */
}

.filter-bar__label-wrapper .filter-bar__label:hover,
.filter-bar__label-wrapper .filter-bar__label:focus {
	color: var(--color-blue);
	transition: color 96ms linear;
}

.filter-bar__toggle:checked ~ .filter-bar__label-wrapper {
	background-color: var(--color-red-dark); /* [4] */
}

.filter-bar__toggle:checked ~ .filter-bar__label-wrapper .filter-bar__label::before {
	content: attr(data-close); /* [3] */
}

/*
 * Filter Bar: Form
 * ================
 * The toggle is used to show and hide the filter form.
 * Purpose:
 * [1] Hide when toggle is not active
 * [2] Make sure the form takes the whole width
 * [3] Show when toggle is active
 */

.filter-bar__form {
	display: none; /* [1] */
	width: 100%; /* [2] */
}

.filter-bar__toggle:checked ~ .filter-bar__form {
	display: block; /* [3] */
}

@media (min-width: 768px) {
	.filter-bar__select-group {
		display: flex;
		align-items: center;
	}
}

@media (min-width: 768px) {
	.filter-bar__right .filter-bar__select-group:not(:last-child) {
		margin-right: 0.5rem;
	}
}

.filter-bar__select:hover {
	cursor: pointer;
}

@media (min-width: 768px) {
	.filter-bar__left .filter-bar__select + .filter-bar__select,
	.filter-bar__right .filter-bar__select + .filter-bar__select {
		margin-left: 0.5rem;
	}
}

@media (max-width: 767px) {
	.filter-bar__left .filter-bar__select + .filter-bar__select:not(.select--transparent),
	.filter-bar__right .filter-bar__select + .filter-bar__select:not(.select--transparent) {
		margin-top: 0.5rem;
	}
}

.filter-bar__label {
	display: block;
}

@media (min-width: 768px) {
	.filter-bar__label {
		display: inline-block;
		margin-right: 1.1875rem;
		margin-bottom: 0;
	}
}

@media (min-width: 768px) {
	.filter-bar__submit {
		margin-left: 0.5rem;
	}
}
