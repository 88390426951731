.hamburger {
	appearance: none;
	background: none;
	border: 0;
	box-shadow: none;
	display: block;
	margin: 0;
	outline: 0;
	padding: 0;
	position: relative;
	width: 19px;
	height: 20px;
}

@media (min-width: 768px) {
	.hamburger {
		width: 29px;
		height: 29px;
	}
}

.hamburger:hover {
	cursor: pointer;
}

.hamburger > div {
	background-color: currentColor;
	position: absolute;
	left: 0;
	width: 100%;
	height: 2px;
}

@media (min-width: 768px) {
	.hamburger > div {
		height: 3px;
	}
}

.hamburger > div:first-child {
	top: 3px;
}

@media (min-width: 768px) {
	.hamburger > div:first-child {
		top: 5px;
	}
}

.hamburger > div:nth-child(2) {
	top: 9px;
}

@media (min-width: 768px) {
	.hamburger > div:nth-child(2) {
		top: 13px;
	}
}

.hamburger > div:first-child,
.hamburger > div:nth-child(2) {
	transform-origin: 50% 50%;
	transition:
		background-color 96ms linear,
		transform 96ms linear;
}

.hamburger > div:last-child {
	top: 15px;
}

@media (min-width: 768px) {
	.hamburger > div:last-child {
		top: 21px;
	}
}

#mobile-navigation-checkbox:checked ~ .site-header .hamburger > div {
	background-color: var(--color-red);
}

#mobile-navigation-checkbox:checked ~ .site-header .hamburger > div:first-child {
	top: 9px;
	transform: rotate(-45deg);
}

@media (min-width: 768px) {
	#mobile-navigation-checkbox:checked ~ .site-header .hamburger > div:first-child {
		top: 13px;
	}
}

#mobile-navigation-checkbox:checked ~ .site-header .hamburger > div:nth-child(2) {
	transform: rotate(45deg);
}

#mobile-navigation-checkbox:checked ~ .site-header .hamburger > div:first-child,
#mobile-navigation-checkbox:checked ~ .site-header .hamburger > div:nth-child(2) {
	background-color: var(--color-red);
}

#mobile-navigation-checkbox:checked ~ .site-header .hamburger > div:last-child {
	display: none;
}
