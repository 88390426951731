.image {
	text-align: left;
}

.info-modal__trigger .image:hover {
	cursor: pointer;
}

.image__image-wrapper {
	position: relative;
	width: 100%;
}

.slideshow .image__image-wrapper:only-child {
	padding-bottom: 2.5rem;
}

.info-modal__trigger .image__image-wrapper::before {
	background-color: rgb(255 65 69 / 0);
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: background-color 160ms linear;
	z-index: 2;
}

.info-modal__trigger:hover .image__image-wrapper::before {
	background-color: rgb(255 65 69 / 0.5);
}

.u-color-detailed--blue .info-modal__trigger .image__image-wrapper::before,
.u-color-detailed--pink .info-modal__trigger .image__image-wrapper::before {
	background-color: rgb(60 70 204 / 0);
}

.u-color-detailed--blue .info-modal__trigger:hover .image__image-wrapper::before,
.u-color-detailed--pink .info-modal__trigger:hover .image__image-wrapper::before {
	background-color: rgb(60 70 204 / 0.75);
}

.image__image {
	display: block;
	width: 100%;
}

.info-modal__trigger .image__image {
	filter: grayscale(0);
	transition: filter 160ms linear;
}

.info-modal__trigger:hover .image__image {
	filter: grayscale(100%);
}

.image__caption {
	display: inline-block;
	font-size: 0.75rem;
	font-weight: var(--font-weight-bold);
	margin: 1rem 0 0;
}

.info-modal__trigger .image__caption {
	padding-left: 0.75rem;
	position: relative;
	text-decoration: none;
	transition: color 96ms linear;
}

.modal .image__caption,
.info-modal__trigger:hover .image__caption {
	color: var(--color-red);
}

.u-color-detailed--blue .info-modal__trigger:hover .image__caption,
.u-color-detailed--pink .info-modal__trigger:hover .image__caption {
	color: var(--color-blue);
}

.info-modal__trigger .image__caption::after,
.info-modal__trigger .image__caption::before {
	border-radius: 0;
	content: "";
	display: block;
	position: absolute;
	top: 0.5rem;
	transform: rotate(45deg);
	width: 0.25rem;
	height: 0.25rem;
}

.info-modal__trigger .image__caption::after {
	background-color: white;
	left: -0.1875rem;
	z-index: 2;
}

.info-modal__trigger .image__caption::before {
	background-color: currentColor;
	left: -0.0625rem;
	z-index: 1;
}

.slideshow .image__caption {
	max-width: 66.6666%;
}
