.search-results {
	display: grid;
	grid-row-gap: 4.3125rem;
	list-style: none;
	margin: 0;
	padding: 0;
	padding-top: var(--space-small);
}

/* mq-from-small */
@media (--mq-from-small) {
	.search-results {
		grid-column-gap: calc(var(--space-small) * 2);
		grid-template-columns: repeat(2, 1fr);
	}
}

/* mq-from-medium */
@media (--mq-from-medium) {
	.search-results {
		column-gap: calc(var(--space-medium) * 2);
		grid-template-columns: repeat(3, 1fr);
		padding-top: var(--space-medium);
	}
}

/* mq-from-wide */
@media (--mq-from-wide) {
	.search-results {
		grid-template-columns: repeat(4, 1fr);
		padding-top: var(--space-large);
	}
}

.search-results__link {
	color: var(--color-black);
	display: flex;
	flex-direction: column;
	font-size: 0.875rem;
	line-height: 1.5625rem;
	justify-content: space-between;
	text-decoration: none;
	height: 100%;
}

.search-results__item__top {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.search-results__item__title {
	color: var(--color-red);
	font-size: 1.125rem;
	font-weight: 500;
	line-height: 1.4375rem;
	text-wrap: balance;
}

.search-results__item__date {
	flex-shrink: 0;
	font-size: 0.75rem;
	font-weight: 500;
	line-height: 1.5625rem;
	padding-left: 1rem;
}

.search-results__item__excerpt {
	font-size: 0.875rem;
	line-height: 1.5625rem;
	margin-top: 1.25rem;
	margin-bottom: 0;
}

.search-results__item hr {
	background-color: transparent;
	border-top: 1px dashed var(--color-black);
	margin-block: 1.25rem;
}

.search-results__item__type {
	color: var(--color-grey);
}
