.page-title-banner {
}

.page-title-banner__title {
	font-weight: var(--font-weight-normal);
	margin-top: 1rem;
	margin-bottom: 3rem;
}

.page-title-banner__meta,
.page-title-banner__subtitle {
	font-size: 0.9375rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.75;
	margin-bottom: 0;
}

@media (--mq-from-small) {
	.page-title-banner__meta,
	.page-title-banner__subtitle {
		font-size: 1rem;
	}
}

.page-title-banner__meta a,
.page-title-banner__meta button,
.page-title-banner__meta p,
.page-title-banner__meta span,
.page-title-banner__subtitle a,
.page-title-banner__subtitle button,
.page-title-banner__subtitle p,
.page-title-banner__subtitle span {
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
}
