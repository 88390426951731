.text-header {
}

.text-header__back-link {
	margin-bottom: 1rem;
}

.text-header__content {
	font-size: 1.125rem;
	line-height: 1.5;
}

@media (--mq-from-small) {
	.text-header__content {
		font-size: 1.25rem;
	}
}

@media (min-width: 1280px) {
	.text-header__content {
		font-size: 1.5rem;
	}
}

.text-header__content p {
	color: currentColor;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
}
