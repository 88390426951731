.tag {
	background-color: var(--color-blue);
	color: white;
	display: inline-block;
	font-size: 0.625rem;
	font-weight: var(--font-weight-bold);
	margin: 0 0.3125rem 0.3125rem 0;
	padding: 0.4375rem;
}

.u-colored--blue .tag,
.u-color-detailed--cyan .tag,
.u-color-detailed--red .tag,
.u-color-detailed--yellow .tag {
	background-color: var(--color-red);
}
