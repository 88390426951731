@media (min-width: 1280px) {
	.doormat-siblings {
		display: none;
	}
}

.doormat-siblings__wrapper {
	box-sizing: border-box;
	padding-right: 6.25%;
	padding-left: 6.25%;
	width: 100%;
}

@media (--mq-from-small) {
	.doormat-siblings__wrapper {
		padding-right: 5%;
		padding-left: 5%;
	}
}

@media (--mq-from-medium) {
	.doormat-siblings__wrapper {
		padding-right: 16.6666%;
		padding-left: 16.6666%;
	}
}
