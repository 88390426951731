.post-list {
	color: black;
	list-style: none;
	margin: 0;
	padding: 0;
}

.post-list__link {
	color: currentColor;
	display: block;
	padding-top: var(--space-x-small);
	padding-bottom: var(--space-x-small);
	text-decoration: none;
	width: 100%;
	height: 100%;
}

@media (--mq-from-small) {
	.post-list__link {
		padding-top: var(--space-small);
		padding-bottom: var(--space-small);
	}
}

@media (--mq-from-medium) {
	.post-list__link {
		padding-top: var(--space-medium);
		padding-bottom: var(--space-medium);
	}
}

@media (--mq-from-wide) {
	.post-list__link {
		padding-top: var(--space-large);
		padding-bottom: var(--space-large);
	}
}

@media (--mq-from-large) {
	.post-list__link {
		padding-top: var(--space-x-large);
		padding-bottom: var(--space-x-large);
	}
}

@media (--mq-from-medium) {
	.post-list__item__wrapper {
		display: flex;
		align-items: flex-start;
	}
}

.post-list__item__title {
	color: currentColor;
	font-weight: var(--font-weight-normal);
}

@media (--mq-from-small) {
	.post-list__item__title {
		line-height: 45px;
	}
}

@media (--mq-from-medium) {
	.post-list__item__title {
		box-sizing: border-box;
		flex-basis: 80%;
		flex-grow: 0;
		flex-shrink: 0;
		margin-bottom: 0;
		padding-right: 2rem;
		text-decoration-color: rgb(0 0 0 / 0);
		text-decoration-line: underline;
		transition:
			text-decoration-color 128ms linear,
			transform 720ms cubic-bezier(0.365, 0, 0.11, 1);
	}
}

@media (--mq-from-medium) {
	.post-list__link.u-colored--blue .post-list__item__title,
	.post-list__link.u-colored--red .post-list__item__title {
		text-decoration-color: rgb(255 255 255 / 0);
	}
}

@media (--mq-from-medium) {
	.post-list__link.u-colored--cyan .post-list__item__title,
	.post-list__link.u-colored--yellow .post-list__item__title {
		text-decoration-color: rgb(255 65 69 / 0);
	}
}

@media (--mq-from-medium) {
	.post-list__link.u-colored--pink .post-list__item__title {
		text-decoration-color: rgb(60 70 204 / 0);
	}
}

@media (--mq-from-medium) {
	.post-list__link:hover .post-list__item__title {
		text-decoration-color: currentColor;
		transform: translateX(1rem);
	}
}

.u-color-detailed--blue .post-list__item__title,
.u-color-detailed--pink .post-list__item__title,
.u-color-detailed--cyan .post-list__item__title,
.u-color-detailed--red .post-list__item__title,
.u-color-detailed--yellow .post-list__item__title {
	color: currentColor;
}

.post-list__item__date {
	font-size: 0.875rem;
	font-weight: var(--font-weight-bold);
	margin-bottom: 0;
}

@media (--mq-from-medium) {
	.post-list__item__date {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 0;
		line-height: 45px;
		text-align: right;
		transition:
			opacity 240ms linear,
			transform 640ms cubic-bezier(0.365, 0, 0.11, 1);
	}
}

@media (--mq-from-medium) {
	.post-list__link:hover .post-list__item__date {
		opacity: 0.2;
		transform: scale(0.95);
	}
}
