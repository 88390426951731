@media (--mq-from-small) {
	.doormat.doormat--image {
		margin-top: calc(60vw - 175px);
		position: relative;
	}
}

@media (--mq-from-large) {
	.doormat.doormat--image {
		margin-top: 648px;
	}
}

.doormat__image {
	position: relative;
	width: 100%;
}

@media (--mq-from-small) {
	.doormat__image {
		position: absolute;
		bottom: 50%;
		left: 0;
		z-index: 1;
	}
}

.doormat__image__image {
	display: block;
	width: 100%;
	z-index: 1;
}

@supports (object-fit: cover) {
	@media (--mq-from-large) {
		.doormat__image__image {
			object-fit: cover;
			object-position: center center;
			height: 823px;
		}
	}
}

.doormat__image__caption {
	color: white;
	font-size: 0.75rem;
	font-weight: var(--font-weight-bold);
	position: absolute;
	top: 6.25vw;
	right: 6.25%;
	z-index: 2;
}

@media (--mq-from-small) {
	.doormat__image__caption {
		top: 2.25rem;
		right: 2.25rem;
	}
}

@media (--mq-up-to-small) {
	.doormat__image + .doormat__content {
		margin-top: -2rem;
	}
}

@media (--mq-from-small) {
	.doormat__image + .doormat__content {
		position: relative;
		z-index: 3;
	}
}
