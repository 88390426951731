.card {
	color: black;
	display: block;
	text-decoration: none;
}

/* use flex to place title before image on mobile */
@media (--mq-up-to-small) {
	.card {
		display: flex;
		flex-direction: column;
	}
}

.card.card--small {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.card.card--large {
	box-sizing: border-box;
	padding: 1rem var(--space-x-small);
}

@media (--mq-from-small) {
	.card.card--large {
		padding: var(--space-small);
	}
}

@media (--mq-from-medium) {
	.card.card--large {
		padding: var(--space-medium);
	}
}

@media (--mq-from-wide) {
	.card.card--large {
		padding: var(--space-large);
	}
}

@media (--mq-from-large) {
	.card.card--large {
		padding: var(--space-x-large);
	}
}

@media (--mq-from-small) {
	.windows .card {
		height: 100%;
	}
}

@media (--mq-from-small) {
	.windows .card {
		padding-right: 12.5%;
		padding-left: 12.5%;
	}
}

@media (--mq-from-large) {
	.windows .card {
		padding-right: calc((200% - 1460px) / 2);
		padding-left: calc((200% - 1460px) / 2);
	}
}

@media (--mq-from-large) {
	.windows .windows__item:nth-child(odd) .card {
		padding-right: var(--space-x-large);
	}
}

@media (--mq-from-large) {
	.windows .windows__item:nth-child(even) .card {
		padding-left: var(--space-x-large);
	}
}
/* everything full width */
@media (--mq-up-to-small) {
	.card > * {
		width: 100%;
	}
}

.card__thumbnail {
	backface-visibility: hidden;
	font-size: 0;
	margin-bottom: 1rem;
	overflow: hidden;
	position: relative;
}

@media (--mq-from-medium) {
	.card__thumbnail {
		margin-bottom: 1.4375rem;
		transition: transform 512ms cubic-bezier(0.365, 0, 0.11, 1);
	}
}

@media (--mq-from-medium) {
	.card:hover .card__thumbnail {
		transform: scale(1.005);
	}
}

@media (--mq-from-medium) {
	.card--small:hover .card__thumbnail {
		transform: scale(1.01);
	}
}

.card__thumbnail::before {
	background-color: rgb(255 65 69 / 0);
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: background-color 160ms linear;
	z-index: 2;
}

.card:hover .card__thumbnail::before {
	background-color: rgb(255 65 69 / 0.5);
}

.card.u-colored--pink .card__thumbnail::before,
.card.u-color-detailed--blue .card__thumbnail::before,
.card.u-color-detailed--pink .card__thumbnail::before {
	background-color: rgb(60 70 204 / 0);
}

.card.u-colored--pink:hover .card__thumbnail::before,
.card.u-color-detailed--blue:hover .card__thumbnail::before,
.card.u-color-detailed--pink:hover .card__thumbnail::before {
	background-color: rgb(60 70 204 / 0.75);
}

.card.u-colored--blue .card__thumbnail::before,
.card.u-colored--red .card__thumbnail::before {
	background-color: rgb(255 255 255 / 0);
}

.card.u-colored--blue:hover .card__thumbnail::before,
.card.u-colored--red:hover .card__thumbnail::before {
	background-color: rgb(255 255 255 / 0.55);
}

@media (--mq-from-wide) {
	.card--large .card__thumbnail {
		margin-bottom: 2rem;
	}
}

@media (--mq-from-large) {
	.card--large .card__thumbnail {
		margin-bottom: 3rem;
	}
}

.card--small .card__thumbnail {
	margin: 0;
}

@media (--mq-from-small) {
	.card--small .card__thumbnail {
		flex-basis: 145px;
		flex-grow: 0;
		flex-shrink: 0;
		order: 1;
	}
}

.card__thumbnail[style*="background-image"] {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-bottom: 66.6666%;
}

.card__thumbnail__image {
	backface-visibility: hidden;
	display: block;
	filter: grayscale(0);
	transition: filter 160ms linear;
	width: 100%;
}

.card:hover .card__thumbnail__image {
	filter: grayscale(100%);
}

.card--small .card__thumbnail__image {
	margin: 0;
}

@media (--mq-up-to-medium) {
	.card--small .card__thumbnail__image {
		display: none;
	}
}

@media (--mq-from-medium) {
	.card--small .card__content-wrapper:not(:only-child) {
		box-sizing: border-box;
		flex-grow: 1;
		flex-shrink: 1;
		order: 0;
		padding-right: 1.75rem;
	}
}

.card__title {
	color: currentColor;
	font-size: 1.0625rem;
	line-height: 1.275;
	margin-top: 0;
	margin-bottom: 1rem;
	overflow-wrap: break-word;
	text-decoration-color: rgb(0 0 0 / 0);
	text-decoration-line: underline;
	transition: text-decoration-color 128ms linear;
	/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
	word-break: break-word;
	word-wrap: break-word;
}

/* title always first on mobile */
@media (--mq-up-to-small) {
	.card__title {
		order: -1;
	}
}

@media (--mq-from-small) {
	.card__title {
		font-size: 1.125rem;
	}
}

@media (--mq-from-medium) {
	.card__title {
		margin-bottom: 1.4375rem;
	}
}

.card--large .card__title {
	font-size: 1.125rem;
	font-weight: var(--font-weight-normal);
	line-height: 1.3333;
}

@media (--mq-from-small) {
	.card--large .card__title {
		font-size: 1.5rem;
	}
}

@media (--mq-from-wide) {
	.card--large .card__title {
		font-size: 2.25rem;
		margin-bottom: 2rem;
	}
}

@media (--mq-from-large) {
	.card--large .card__title {
		margin-bottom: 3rem;
	}
}

.card.u-colored--blue .card__title {
	text-decoration-color: rgb(255 255 255 / 0);
}

.card.u-colored--cyan .card__title,
.card.u-colored--yellow .card__title {
	text-decoration-color: rgb(255 65 69 / 0);
}

.card.u-colored--pink .card__title {
	text-decoration-color: rgb(60 70 204 / 0);
}

.card.u-colored--red .card__title {
	text-decoration-color: rgb(255 255 255 / 0);
}

.card--small .card__title {
	margin: 0;
}

@media (--mq-up-to-small) {
	.card--small .card__title {
		font-size: 0.9375rem;
		width: 60vw;
	}
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.card--small .card__title {
		width: 30vw;
	}
}

@media (--mq-up-to-medium) {
	.card--small .card__title {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.card:hover .card__title {
	text-decoration-color: currentColor;
}

.card__date,
.card__description {
	font-size: 0.75rem;
	overflow-wrap: break-word;
	/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
	word-break: break-word;
	word-wrap: break-word;
}

@media (--mq-from-small) {
	.card__date,
	.card__description {
		font-size: 0.875rem;
	}
}

.card__date:last-child,
.card__description:last-child {
	margin-bottom: 0;
}

.card--small .card__date,
.card--small .card__description {
	font-size: 1rem;
}

@media (--mq-from-small) {
	.card--small .card__date,
	.card--small .card__description {
		font-size: 1rem;
	}
}

.card:not(.card--small) .card__date {
	font-weight: var(--font-weight-bold);
}

@media (--mq-from-medium) {
	.card--large .card__date {
		display: inline-block;
		transition: opacity 128ms linear;
	}
}

@media (--mq-from-medium) {
	.card--large:hover .card__date {
		opacity: 0.4;
	}
}

@media (--mq-from-medium) {
	.card__description:last-child {
		transition: opacity 128ms linear;
	}
}

@media (--mq-from-medium) {
	.card:hover .card__description:last-child {
		opacity: 0.4;
	}
}

.card__info {
	font-size: 0.75rem;
}

@media (--mq-from-small) {
	.card__info {
		font-size: 0.875rem;
	}
}

@media (--mq-from-medium) {
	.card__info {
		transition: opacity 128ms linear;
	}
}

.card__info p {
	font-size: inherit;
	margin-bottom: 0;
}

.card:not(.card--small) .card__info {
	padding-top: 1rem;
	position: relative;
}

.card:not(.card--small) .card__info p {
	font-weight: var(--font-weight-bold);
}

.card:not(.card--small) .card__info::before {
	background-image: url("../images/dot.svg");
	background-repeat: repeat-x;
	background-size: 4px 1px;
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 1px;
}

@media (--mq-from-medium) {
	.card:hover .card__info {
		opacity: 0.4;
	}
}

.card__tags {
	color: var(--color-grey);
}

.card__tags:not(:empty) {
	margin-top: 1rem;
}

.card--small .card__tags {
	margin-top: 0.25rem;
}
