@media (--mq-from-small) {
	.grid {
		display: flex;
		flex-wrap: wrap;
		margin-right: calc(var(--space-small) * -1);
		margin-left: calc(var(--space-small) * -1);
		overflow: hidden;
		justify-content: center;
	}
}

@media (--mq-from-medium) {
	.grid {
		margin-right: calc(var(--space-medium) * -1);
		margin-left: calc(var(--space-medium) * -1);
	}
}

@media (--mq-from-wide) {
	.grid {
		margin-right: calc(var(--space-large) * -1);
		margin-left: calc(var(--space-large) * -1);
	}
}

@media (--mq-from-large) {
	.grid {
		margin-right: calc(var(--space-x-large) * -1);
		margin-left: calc(var(--space-x-large) * -1);
	}
}

.grid.grid--small {
	justify-content: flex-start;
}

.grid__item {
	margin-bottom: var(--space-x-small);
	overflow: hidden;
}

@media (--mq-from-small) {
	.grid__item {
		box-sizing: border-box;
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
		margin-bottom: var(--space-small);
		padding-right: var(--space-small);
		padding-left: var(--space-small);
	}
}

@media (--mq-from-medium) {
	.grid__item {
		flex-basis: 33.3333%;
		margin-bottom: var(--space-medium);
		padding-right: var(--space-medium);
		padding-left: var(--space-medium);
	}
}

@media (--mq-from-wide) {
	.grid--small .grid__item {
		flex-basis: 25%;
	}
}
