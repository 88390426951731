.spotlight-feed {
	background-color: white;
	position: relative;
}

.spotlight-feed::before {
	background-image: url("../images/dot.svg");
	background-repeat: repeat-x;
	background-size: 4px 1px;
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 1px;
}

@media (max-width: 1023px) {
	.spotlight-feed__wrapper.wrapper {
		padding-right: 0;
		padding-left: 0;
	}
}

@media (min-width: 1024px) {
	.spotlight-feed__wrapper.wrapper {
		display: flex;
		align-items: stretch;
	}
}

.spotlight-feed__group {
	padding-top: var(--space-x-small);
	padding-bottom: var(--space-x-small);
}

@media (min-width: 768px) {
	.spotlight-feed__group {
		padding-top: var(--space-small);
		padding-bottom: var(--space-small);
	}
}

@media (min-width: 1024px) {
	.spotlight-feed__group {
		box-sizing: border-box;
		display: flex;
		flex-basis: 50%;
		flex-direction: column;
		padding-top: 0;
		padding-bottom: var(--space-medium);
	}
}

@media (min-width: 1380px) {
	.spotlight-feed__group {
		padding-bottom: var(--space-large);
	}
}

@media (min-width: 1680px) {
	.spotlight-feed__group {
		padding-bottom: var(--space-x-large);
	}
}

.spotlight-feed__group:first-child {
	position: relative;
}

@media (min-width: 1024px) {
	.spotlight-feed__group:first-child {
		padding-right: var(--space-medium);
	}
}

@media (min-width: 1380px) {
	.spotlight-feed__group:first-child {
		padding-right: var(--space-large);
	}
}

@media (min-width: 1680px) {
	.spotlight-feed__group:first-child {
		padding-right: var(--space-x-large);
	}
}

.spotlight-feed__group:first-child::after {
	background-image: url("../images/dot.svg");
	background-repeat: repeat-x;
	background-size: 4px 1px;
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1px;
}

@media (min-width: 1024px) {
	.spotlight-feed__group:first-child::after {
		background-image: url("../images/dot-vertical.svg");
		background-repeat: repeat-y;
		background-size: 1px 4px;
		top: 4px;
		right: 0;
		bottom: auto;
		left: auto;
		width: 1px;
		height: calc(100% - 4px);
	}
}

@media (min-width: 1024px) {
	.spotlight-feed__group:last-child {
		padding-left: var(--space-medium);
	}
}

@media (min-width: 1380px) {
	.spotlight-feed__group:last-child {
		padding-left: var(--space-large);
	}
}

@media (min-width: 1680px) {
	.spotlight-feed__group:last-child {
		padding-left: var(--space-x-large);
	}
}

.spotlight-feed__group[hidden] {
	display: none;
}

@media (max-width: 767px) {
	.spotlight-feed__posts {
		-webkit-overflow-scrolling: touch;
		margin-bottom: 1.25vw;
		padding-bottom: 5vw;
		padding-left: 6.25vw;
		scroll-padding-left: 6.25vw;
		scroll-snap-type: x mandatory;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.spotlight-feed__posts {
		margin-bottom: 3vw;
		padding-bottom: 7vw;
		padding-left: 10vw;
	}
}

@media (max-width: 1023px) {
	.spotlight-feed__posts {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		overflow-x: scroll;
	}
}

@media (min-width: 1024px) {
	.spotlight-feed__posts {
		order: 999;
		width: 100%;
	}
}

@media (max-width: 767px) {
	.spotlight-feed__posts__item {
		flex-basis: 60vw;
		margin-right: 12.5vw;
		scroll-snap-align: start;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.spotlight-feed__posts__item {
		flex-basis: 30vw;
		margin-right: var(--space-small);
	}
}

@media (max-width: 1023px) {
	.spotlight-feed__posts__item {
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (min-width: 1024px) {
	.spotlight-feed__posts__item {
		margin-bottom: var(--space-medium);
	}
}

@media (min-width: 1380px) {
	.spotlight-feed__posts__item {
		margin-bottom: var(--space-large);
	}
}

@media (max-width: 767px) {
	.spotlight-feed__posts__item:last-child {
		padding-right: 33.75vw;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.spotlight-feed__posts__item:last-child {
		padding-right: 10vw;
	}
}

@media (max-width: 767px) {
	.spotlight-feed__title,
	.spotlight-feed__view-all-link {
		padding-right: 6.25%;
		padding-left: 6.25%;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.spotlight-feed__title,
	.spotlight-feed__view-all-link {
		padding-right: 10%;
		padding-left: 10%;
	}
}

.spotlight-feed__title {
	font-size: 1.25rem;
	line-height: 1.275;
}

@media (max-width: 767px) {
	.spotlight-feed__title {
		margin-bottom: var(--space-small);
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.spotlight-feed__title {
		margin-bottom: var(--space-medium);
	}
}

@media (min-width: 1024px) {
	.spotlight-feed__title {
		background-color: white;
		font-size: 1.0625rem;
		margin-bottom: 0;
		padding: 0.125rem 1rem;
		transform: translate(-1rem, -50%);
		width: max-content;
	}
}

@media (min-width: 1380px) {
	.spotlight-feed__title {
		font-size: 1.375rem;
		padding: 0.125rem 1.75rem;
		transform: translate(-1.75rem, -50%);
	}
}

.spotlight-feed__view-all-link {
	transition: text-decoration-color 96ms linear;
}

@media (min-width: 1024px) {
	.spotlight-feed__view-all-link {
		margin-top: 1rem;
		margin-bottom: var(--space-medium);
		width: 100%;
	}
}

@media (min-width: 1380px) {
	.spotlight-feed__view-all-link {
		margin-bottom: var(--space-large);
	}
}

@media (min-width: 1680px) {
	.spotlight-feed__view-all-link {
		margin-bottom: var(--space-x-large);
	}
}

.spotlight-feed__group--blue .spotlight-feed__view-all-link {
	color: var(--color-blue);
}

.spotlight-feed__group--blue .spotlight-feed__view-all-link:hover {
	text-decoration-color: rgb(60 70 204 / 0);
}

.spotlight-feed__group--red .spotlight-feed__view-all-link {
	color: var(--color-red);
}

.spotlight-feed__group--red .spotlight-feed__view-all-link:hover {
	text-decoration-color: rgb(255 65 69 / 0);
}
