@media (--mq-from-small) {
	.picture-text {
		line-height: 42px;
	}
}

@media (min-width: 1280px) {
	.picture-text {
		line-height: 58px;
	}
}

@media (--mq-from-wide) {
	.picture-text {
		line-height: 78px;
	}
}

@media (--mq-up-to-small) {
	.post-list .picture-text {
		padding-bottom: calc(58.3333vw + 1.25rem);
		position: relative;
	}
}

@media (--mq-from-small) {
	.post-list .picture-text {
		line-height: 45px;
	}
}

.picture-text__item {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	color: currentColor;
	display: inline-block;
	margin: 0;
	outline: 0;
	padding: 0;
	text-decoration: none;
}

@media (--mq-from-small) {
	.picture-text__item {
		display: inline-flex;
		align-items: center;
		white-space: nowrap;
		width: max-content;
	}
}

button.picture-text__item:hover {
	cursor: pointer;
}

.picture-text__label {
	color: currentColor;
}

@media (--mq-from-small) {
	.picture-text__label {
		transition: color 96ms linear;
	}
}

@media (--mq-up-to-small) {
	button.picture-text__item .picture-text__label {
		text-decoration: underline;
	}
}

@media (--mq-from-small) {
	button.picture-text__item:hover .picture-text__label {
		color: var(--color-red);
	}
}

.u-color-detailed--blue button.picture-text__item:hover .picture-text__label,
.u-color-detailed--pink button.picture-text__item:hover .picture-text__label {
	color: var(--color-blue);
}

.u-color-detailed--cyan button.picture-text__item:hover .picture-text__label,
.u-color-detailed--yellow button.picture-text__item:hover .picture-text__label {
	color: var(--color-red);
}

@media (--mq-up-to-small) {
	.post-list .picture-text__label {
		text-decoration: none;
	}
}

@media (--mq-up-to-small) {
	.picture-text__thumbnail {
		display: none;
	}
}

@media (--mq-from-small) {
	.picture-text__thumbnail {
		background-color: var(--color-grey-light);
		display: block;
		margin-left: 8px;
		width: auto;
		height: 42px;
	}
}

@media (--mq-from-medium) {
	.picture-text__thumbnail {
		position: relative;
		transition: transform 512ms cubic-bezier(0.365, 0, 0.11, 1);
	}
}

@media (min-width: 1280px) {
	.picture-text__thumbnail {
		margin-left: 10px;
		height: 58px;
	}
}

@media (--mq-from-wide) {
	.picture-text__thumbnail {
		margin-left: 11px;
		height: 78px;
	}
}

@media (--mq-from-medium) {
	button.picture-text__item:hover .picture-text__thumbnail {
		transform: scale(1.01);
	}
}

@media (--mq-from-medium) {
	.picture-text__thumbnail::before {
		background-color: rgb(255 65 69 / 0);
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: background-color 160ms linear;
		z-index: 2;
	}
}

@media (--mq-from-medium) {
	button.picture-text__item:hover .picture-text__thumbnail::before {
		background-color: rgb(255 65 69 / 0.5);
	}
}

.u-color-detailed--blue .picture-text__thumbnail::before,
.u-color-detailed--pink .picture-text__thumbnail::before {
	background-color: rgb(60 70 204 / 0);
}

.u-color-detailed--blue button.picture-text__item:hover .picture-text__thumbnail::before,
.u-color-detailed--pink button.picture-text__item:hover .picture-text__thumbnail::before {
	background-color: rgb(60 70 204 / 0.75);
}

@media (--mq-up-to-small) {
	.post-list *.picture-text__item:first-of-type .picture-text__thumbnail {
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 87.5vw;
		height: 58.3333vw;
	}
}

@media (--mq-from-small) {
	.post-list .picture-text__thumbnail {
		background: none;
		width: 67px;
		height: 45px;
	}
}

.picture-text__thumbnail__image {
	backface-visibility: hidden;
	display: block;
	filter: grayscale(0);
	position: relative;
	transition: filter 160ms linear;
	width: auto;
	max-width: 100%;
	height: 100%;
	z-index: 1;
}

button.picture-text__item:hover .picture-text__thumbnail__image {
	filter: grayscale(100%);
}
