.social-links {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: center;
}

.social-links__item {
	margin: 0 0.25rem;
}

@media (--mq-from-medium) {
	.social-links__item {
		margin: 0 0.5rem;
	}
}

.social-links__link {
	background-color: var(--color-blue);
	border-radius: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color 96ms linear;
	width: 28px;
	height: 28px;
}

@media (--mq-from-medium) {
	.social-links__link {
		border-radius: 20px;
		width: 20px;
		height: 20px;
	}
}

.social-links__link:hover {
	background-color: var(--color-red);
}

.social-links__link .icon {
	display: block;
	fill: white;
	width: 16px;
	height: 16px;
}

@media (--mq-from-medium) {
	.social-links__link .icon {
		width: 10px;
		height: 10px;
	}
}
