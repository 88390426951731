.u-centered {
	text-align: center;
}

.u-align-right {
	text-align: right;
	width: 100%;
}

.u-colored {
	background: none;
}

.u-colored.u-colored--blue {
	background-color: var(--color-blue);
	color: white;
}

.u-colored.u-colored--cyan {
	background-color: var(--color-cyan);
	color: var(--color-red);
}

.u-colored.u-colored--pink {
	background-color: var(--color-pink);
	color: var(--color-blue);
}

.u-colored.u-colored--red {
	background-color: var(--color-red);
	color: white;
}

.u-colored.u-colored--yellow {
	background-color: var(--color-yellow);
	color: var(--color-red);
}
