.wrapper {
	box-sizing: border-box;
	padding-right: 6.25%;
	padding-left: 6.25%;
	width: 100%;
}

@media (--mq-from-small) {
	.wrapper {
		padding-right: 10%;
		padding-left: 10%;
	}
}

@media (--mq-from-large) {
	.wrapper {
		padding-right: calc((100% - 1180px) / 2);
		padding-left: calc((100% - 1180px) / 2);
	}
}

@media (--mq-from-small) {
	.wrapper.wrapper--full-width {
		padding-right: 2.25rem;
		padding-left: 2.25rem;
	}
}

@media (--mq-up-to-large) {
	.wrapper.wrapper--large {
		padding-right: 6.25%;
		padding-left: 6.25%;
	}
}

@media (--mq-from-large) {
	.wrapper.wrapper--large {
		padding-right: calc((100% - 1460px) / 2);
		padding-left: calc((100% - 1460px) / 2);
	}
}

@media (--mq-from-small) and (max-width: 1279px) {
	.wrapper.wrapper--medium {
		padding-right: 15%;
		padding-left: 15%;
	}
}

@media (min-width: 1280px) {
	.wrapper.wrapper--medium {
		padding-right: 20%;
		padding-left: 20%;
	}
}

@media (--mq-from-large) {
	.wrapper.wrapper--medium {
		padding-right: calc((100% - 1012px) / 2);
		padding-left: calc((100% - 1012px) / 2);
	}
}

@media (--mq-from-small) and (max-width: 1279px) {
	.wrapper.wrapper--small {
		padding-right: 20%;
		padding-left: 20%;
	}
}

@media (min-width: 1280px) {
	.wrapper.wrapper--small {
		padding-right: 27.5%;
		padding-left: 27.5%;
	}
}

@media (--mq-from-large) {
	.wrapper.wrapper--small {
		padding-right: calc((100% - 692px) / 2);
		padding-left: calc((100% - 692px) / 2);
	}
}

@media (--mq-from-small) and (max-width: 1279px) {
	.wrapper.wrapper--x-small {
		padding-right: 25%;
		padding-left: 25%;
	}
}

@media (min-width: 1280px) {
	.wrapper.wrapper--x-small {
		padding-right: 33.3333%;
		padding-left: 33.3333%;
	}
}

@media (--mq-from-large) {
	.wrapper.wrapper--x-small {
		padding-right: calc((100% - 552px) / 2);
		padding-left: calc((100% - 552px) / 2);
	}
}
