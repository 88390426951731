body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: var(--font-sans);
	font-weight: var(--font-weight-normal);
}

*::selection {
	background-color: black;
	color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--font-sans);
	font-weight: var(--font-weight-bold);
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
}

@media (--mq-from-small) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 1.75rem;
		margin-bottom: 1.75rem;
	}
}

@media (--mq-from-wide) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 2.25rem;
		margin-bottom: 2.25rem;
	}
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
	margin-bottom: 0;
}

h1:only-child,
h2:only-child,
h3:only-child,
h4:only-child,
h5:only-child,
h6:only-child {
	margin-top: 0;
	margin-bottom: 0;
}

.u-color-detailed--blue h2,
.u-color-detailed--pink h2,
.u-color-detailed--blue h3,
.u-color-detailed--pink h3,
.u-color-detailed--blue h4,
.u-color-detailed--pink h4,
.u-color-detailed--blue h5,
.u-color-detailed--pink h5,
.u-color-detailed--blue h6,
.u-color-detailed--pink h6 {
	color: var(--color-blue);
}

.u-color-detailed--cyan h2,
.u-color-detailed--red h2,
.u-color-detailed--yellow h2,
.u-color-detailed--cyan h3,
.u-color-detailed--red h3,
.u-color-detailed--yellow h3,
.u-color-detailed--cyan h4,
.u-color-detailed--red h4,
.u-color-detailed--yellow h4,
.u-color-detailed--cyan h5,
.u-color-detailed--red h5,
.u-color-detailed--yellow h5,
.u-color-detailed--cyan h6,
.u-color-detailed--red h6,
.u-color-detailed--yellow h6 {
	color: var(--color-red);
}

h1 {
	font-size: 1.875rem;
	line-height: 1.5;
}

@media (--mq-from-small) {
	h1 {
		font-size: 2.5rem;
	}
}

@media (--mq-from-wide) {
	h1 {
		font-size: 3.125rem;
	}
}

h2 {
	font-size: 1.375rem;
	line-height: 1.3333;
}

@media (--mq-from-small) {
	h2 {
		font-size: 2rem;
	}
}

@media (--mq-from-wide) {
	h2 {
		font-size: 2.25rem;
	}
}

h3,
.editorial-text-content h2 {
	font-size: 1.0625rem;
	line-height: 1.275;
}

@media (--mq-from-small) {
	h3,
	.editorial-text-content h2 {
		font-size: 1.375rem;
	}
}

h4,
.editorial-text-content h3 {
	font-size: 0.9375rem;
	line-height: 1.75;
}

@media (--mq-from-small) {
	h4,
	.editorial-text-content h3 {
		font-size: 1rem;
	}
}

h5,
h6,
.editorial-text-content h4 {
	font-size: 0.75rem;
	line-height: 1.275;
	text-transform: uppercase;
}

p {
	font-size: 1rem;
	line-height: 1.75;
	margin: 0 0 1rem 0;
}

p:only-child:not([class]),
p:last-child:not([class]) {
	margin-bottom: 0;
}

a:not([class]) {
	color: currentColor;
	transition: color 96ms linear;
}

a:not([class]):hover {
	color: var(--color-red);
}

.u-colored--cyan a:not([class]):hover,
.u-colored--red a:not([class]):hover,
.u-colored--yellow a:not([class]):hover {
	color: var(--color-blue);
}

.u-color-detailed--blue a:not([class]),
.u-color-detailed--pink a:not([class]),
.u-color-detailed--cyan a:not([class]):hover,
.u-color-detailed--red a:not([class]):hover,
.u-color-detailed--yellow a:not([class]):hover {
	color: var(--color-blue);
}

.u-color-detailed--cyan a:not([class]),
.u-color-detailed--red a:not([class]),
.u-color-detailed--yellow a:not([class]),
.u-color-detailed--blue a:not([class]):hover,
.u-color-detailed--pink a:not([class]):hover {
	color: var(--color-red);
}

b,
strong {
	font-weight: var(--font-weight-bold);
}

button:not([class]) {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	color: currentColor;
	display: inline-block;
	margin: 0;
	outline: 0;
	padding: 0;
	text-decoration: none;
}

button:not([class]):hover {
	cursor: pointer;
}

a.is-disabled,
button.is-disabled,
h1.is-disabled,
h2.is-disabled,
h3.is-disabled,
h4.is-disabled,
h5.is-disabled,
p.is-disabled {
	cursor: pointer;
	opacity: 0.1;
	pointer-events: none;
	user-select: none;
}

blockquote {
	color: var(--color-blue);
	display: block;
	font-size: 1.375rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.275;
	margin: var(--space-x-small) auto;
	text-align: center;
	max-width: 420px;
}

.u-color-detailed--cyan blockquote,
.u-color-detailed--red blockquote,
.u-color-detailed--yellow blockquote {
	color: var(--color-red);
}

@media (--mq-from-small) {
	blockquote {
		font-size: 1.75rem;
		margin-top: var(--space-small);
		margin-bottom: var(--space-small);
	}
}

@media (--mq-from-medium) {
	blockquote {
		margin-top: var(--space-medium);
		margin-bottom: var(--space-medium);
	}
}

@media (--mq-from-wide) {
	blockquote {
		font-size: 2.125rem;
		margin-top: var(--space-large);
		margin-bottom: var(--space-large);
	}
}

@media (--mq-from-large) {
	blockquote {
		margin-top: var(--space-x-large);
		margin-bottom: var(--space-x-large);
	}
}

blockquote p {
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
}
