.warning {
	border-color: var(--color-blue);
	border-style: solid;
	border-width: 1px;
	box-sizing: border-box;
	color: var(--color-blue);
	font-size: 1rem;
	font-weight: var(--font-weight-normal);
	line-height: 1.75;
	padding: 0.75rem 1rem;
	text-align: center;
	width: 100%;
}

.warning.warning--blue,
.warning.warning--pink {
	border-color: blue;
	color: blue;
}

.warning.warning--cyan,
.warning.warning--red,
.warning.warning--yellow {
	border-color: red;
	color: red;
}
