.site-footer__newsletter {
	font-size: 1.0625rem;
	line-height: 1.275;
	text-align: center;
}

@media (--mq-from-small) {
	.site-footer__newsletter {
		font-size: 1.375rem;
	}
}

.site-footer__newsletter p {
	font-size: inherit;
	line-height: inherit;
}

.site-footer__newsletter a {
	color: var(--color-blue);
	white-space: nowrap;
}

.site-footer__newsletter a:hover {
	color: var(--color-red);
}

.site-footer__navigation {
	display: flex;
	margin-bottom: var(--space-x-small);
}

@media (--mq-up-to-medium) {
	.site-footer__navigation {
		flex-wrap: wrap;
		text-align: center;
	}
}

@media (--mq-from-medium) {
	.site-footer__navigation {
		align-items: stretch;
	}
}

@media (--mq-up-to-medium) {
	.site-footer__navigation__meta,
	.site-footer__navigation__policies,
	.site-footer__navigation__social {
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (--mq-from-medium) {
	.site-footer__navigation__meta,
	.site-footer__navigation__policies {
		flex-basis: 50%;
		flex-grow: 1;
		flex-shrink: 1;
	}
}

@media (--mq-up-to-medium) {
	.site-footer__navigation__meta {
		order: 0;
	}
}

@media (--mq-up-to-medium) {
	.site-footer__navigation__policies {
		margin-bottom: var(--space-x-small);
		order: 1;
	}
}

@media (--mq-from-medium) {
	.site-footer__navigation__policies {
		text-align: right;
	}
}

.site-footer__navigation__social {
	text-align: center;
}

@media (--mq-up-to-medium) {
	.site-footer__navigation__social {
		order: 3;
	}
}

@media (--mq-from-medium) {
	.site-footer__navigation__social {
		display: flex;
		flex-basis: 200px;
		flex-grow: 0;
		flex-shrink: 0;
		justify-content: center;
		align-items: center;
	}
}

.site-footer__logo {
	background-image: url("../images/logo-vlaamse-overheid.svg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	display: block;
	margin: 0 auto;
	transition: opacity 96ms linear;
	width: 140px;
	height: 60px;
}

.site-footer__logo:hover {
	opacity: 0.6;
}

.site-footer__logo span {
	display: none;
}
