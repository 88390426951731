.back-link {
	color: grey;
	display: flex;
	align-items: center;
	text-decoration: none;
	text-transform: lowercase;
	transition: color 96ms linear;
	width: max-content;
}

@media (--mq-from-wide) {
	.back-link {
		font-size: 1.125rem;
	}
}

.u-colored--red .back-link,
.u-colored--blue .back-link {
	color: white;
}

.u-colored--cyan .back-link,
.u-colored--yellow .back-link {
	color: var(--color-red);
}

.u-colored--pink .back-link {
	color: var(--color-blue);
}

.u-centered .back-link {
	margin-right: auto;
	margin-left: auto;
}

.back-link:hover {
	color: black;
}

.u-colored--blue .back-link:hover,
.u-colored--pink .back-link:hover {
	color: var(--color-red);
}

.u-colored--cyan .back-link:hover,
.u-colored--red .back-link:hover,
.u-colored--yellow .back-link:hover {
	color: var(--color-blue);
}

.back-link .icon {
	display: block;
	fill: currentColor;
	margin-right: 0.9375rem;
	transform-origin: 100% 100%;
	transition:
		fill 96ms linear,
		transform 96ms linear;
	width: 17px;
	height: 17px;
}

.back-link:hover .icon {
	transform: rotate(-5deg) scale(1.1);
}

.back-link__label {
	color: currentColor;
	font-size: inherit;
	font-weight: inherit;
}
