/*
 * Styleguide CSS
 *
 * This stylesheets only functions for styleguide purpose
 */

/**
 * Intro
 */

.l-styleguide-intro {
	padding: 100px 20px;
	text-align: center;
}

.l-styleguide-intro p {
	color: #939393;
	margin-right: auto;
	margin-left: auto;
	max-width: 600px;
}

.l-styleguide-intro__logo {
	display: inline-block;
	width: 200px;
}

.l-styleguide-intro__logo svg {
	fill: #3c46cc;
	width: 100%;
}

.l-styleguide-intro__title {
	font-size: 1.625rem;
	margin-bottom: 1em;
}

/**
 * Section
 */

.l-styleguide-section {
	border-top: 1px solid rgb(230 230 230);
	padding-top: 3rem;
	padding-bottom: 4rem;
}

.l-styleguide-section:nth-child(odd) {
	background-color: #fbfbfb;
}

.l-styleguide-section__title {
	padding-right: 1rem;
	padding-bottom: 1rem;
	padding-left: 1rem;
	position: relative;
	text-align: center;
}

.l-styleguide-section__title a {
	border: none;
	color: black;
	font-size: 1.375rem;
	position: relative;
	text-decoration: none;
}

.l-styleguide-section__title a::before {
	color: rgb(200 200 200);
	content: "∞";
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translate(-150%, -50%);
	transform: translate(-150%, -50%);
	-webkit-transition: opacity 303ms;
	transition: opacity 303ms;
}

.l-styleguide-section__title:hover a {
	color: black;
}

.l-styleguide-section__title:hover a::before {
	opacity: 1;
}

.l-styleguide-section__comment {
	padding-right: 1rem;
	padding-bottom: 1rem;
	padding-left: 1rem;
	position: relative;
	text-align: center;
}

/**
 * Wrapper
 */

.l-styleguide-wrapper {
	margin: 0 auto;
	padding: 20px;
	max-width: 740px;
}

.l-styleguide-wrapper.l-styleguide-wrapper--centered {
	text-align: center;
}

/**
 * Colors
 */

.l-styleguide-colors {
	display: flex;
	flex-wrap: wrap;
	text-align: center;
}

.l-styleguide-colors dd,
.l-styleguide-colors dl,
.l-styleguide-colors dt {
	padding: 0;
}

.l-styleguide-colors__color {
	flex-basis: 50%;
	margin-bottom: 20px;
}

@media (--mq-from-small) {
	.l-styleguide-colors__color {
		flex-basis: 33.3333%;
	}
}

.l-styleguide-colors__color__sample {
	border-radius: 50%;
	display: inline-block;
	margin: 0;
	width: 35px;
	height: 35px;
}

.l-styleguide-colors__color__sample.l-styleguide-colors__color__sample--bordered {
	border: 1px solid #939393;
}

.l-styleguide-colors__color__name {
	font-family: monospace;
}

/**
 * Navigation
 */

#l-styleguide-nav__checkbox {
	display: none;
}

.l-styleguide-nav__drawer {
	background-color: #f0f0f0;
	border-right: 1px solid #c5c4c5;
	box-sizing: border-box;
	overflow: auto;
	padding: 5.25rem 1.25rem 1.25rem;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	transform: translateX(-250px);
	width: 250px;
	z-index: 998;
}

@media (--mq-from-small) {
	.l-styleguide-nav__drawer {
		padding: 6.25rem 2.25rem 2.25rem;
		transform: translateX(-33.3333vw);
		width: 33.3333vw;
	}
}

#l-styleguide-nav__checkbox:checked ~ .l-styleguide-nav__drawer {
	transform: translate(0, 0);
}

.l-styleguide-nav__close {
	background-color: rgb(0 0 0 / 0);
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 997;
}

.l-styleguide-nav__close:hover {
	background-color: rgb(0 0 0 / 0.2);
	cursor: pointer;
}

#l-styleguide-nav__checkbox:checked ~ .l-styleguide-nav__close {
	display: block;
}

.l-styleguide-nav__toggle {
	background-color: #fff799;
	color: black;
	cursor: pointer;
	font-family: monospace;
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.25;
	position: fixed;
	text-align: center;
	top: 1.25rem;
	left: 6.25%;
	z-index: 999;
}

@media (--mq-from-small) {
	.l-styleguide-nav__toggle {
		top: 2.25rem;
		left: 2.25rem;
	}
}

.l-styleguide-nav__toggle::before {
	content: "Show";
}

#l-styleguide-nav__checkbox:checked ~ .l-styleguide-nav__toggle {
	background-color: transparent;
	color: #939393;
}

#l-styleguide-nav__checkbox:checked ~ .l-styleguide-nav__toggle::before {
	content: "Hide";
}

#l-styleguide-nav__checkbox:checked ~ .l-styleguide-nav__close:hover ~ .l-styleguide-nav__toggle {
	background-color: #fff799;
}

/**
 * Code
 */

.l-styleguide-code code {
	background-color: rgb(0 0 0 / 0.1);
	display: block;
	font-size: 14px;
	padding: 20px;
}

.l-styleguide-code code:not(:last-child) {
	padding: 20px 20px 0 20px;
}

.l-styleguide-placeholder {
	background-color: rgb(0 0 0 / 0.25);
	width: 10rem;
	max-width: 75%;
	height: 5rem;
}

.l-styleguide-placeholder.l-styleguide-placeholder--large {
	height: 10rem;
}
