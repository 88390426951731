:root {
	--color-blue: #3c46cc;
	--color-cyan: #c8eced;
	--color-grey: #bfbfbf;
	--color-grey-light: #e3e3e3;
	--color-pink: #fad0d8;
	--color-red: #ff4145;
	--color-red-contrast: #bd1216;
	--color-red-dark: #e13c42;
	--color-yellow: #fff475;
	--color-white: #ffffff;
	--color-black: #000000;
	/*  */
	--font-sans: "stolzl", sans-serif;
	--font-weight-normal: 300;
	--font-weight-bold: 500;
	/*  */
	--space-x-small: 2rem;
	--space-small: 2.25rem;
	--space-medium: 3rem;
	--space-large: 4rem;
	--space-x-large: 5rem;
}
