.search-bar {
	background-color: var(--color-yellow);
	padding-block: 1.25rem;
}

/* mq-from-xsmall */
@media (--mq-from-xsmall) {
	.search-bar__form {
		display: flex;
		align-items: center;
	}
}

.search-bar__form__input {
	background-color: var(--color-white);
}

/* mq-up-to-xsmall */
@media (--mq-up-to-xsmall) {
	.search-bar__form__input {
		margin-bottom: 0.9375rem;
	}
}

/* mq-from-xsmall */
@media (--mq-from-xsmall) {
	.search-bar__form__input {
		margin-right: 1.25rem;
		max-width: 320px;
	}
}

.search-bar__form button {
	text-decoration: underline;
}

.search-bar__form button:hover {
	text-decoration: none;
}
