.related-posts {
}

.related-posts__header {
	color: black;
	margin-bottom: calc(var(--space-x-small) - 5px);
	text-align: center;
}

@media (--mq-from-small) {
	.related-posts__header {
		margin-bottom: calc(var(--space-small) - 5px);
	}
}

@media (--mq-from-medium) {
	.related-posts__header {
		margin-bottom: calc(var(--space-medium) - 5px);
	}
}

@media (--mq-from-wide) {
	.related-posts__header {
		margin-bottom: calc(var(--space-large) - 5px);
	}
}

@media (--mq-from-large) {
	.related-posts__header {
		margin-bottom: calc(var(--space-x-large) - 5px);
	}
}

.related-posts__header h2 {
	color: black !important;
	font-size: 1.0625rem;
	line-height: 1.275;
}

@media (--mq-from-small) {
	.related-posts__header h2 {
		font-size: 1.375rem;
	}
}
