.navigation-list {
	font-size: inherit;
	line-height: inherit;
	list-style: none;
	margin: 0;
	padding: 0;
	text-transform: lowercase;
}

.navigation-list.navigation-list--horizontal {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

@media (max-width: 1023px) {
	.site-footer__navigation .navigation-list.navigation-list--horizontal {
		justify-content: center;
	}
}

@media (min-width: 1024px) {
	.site-footer__navigation__policies .navigation-list.navigation-list--horizontal {
		justify-content: flex-end;
	}
}

.navigation-list.navigation-list--small {
	font-size: 0.875rem;
}

@media (min-width: 1024px) {
	.navigation-list.navigation-list--large {
		font-size: 1.25rem;
	}
}

@media (min-width: 1380px) {
	.navigation-list.navigation-list--large {
		font-size: 1.5rem;
	}
}

@media (max-width: 767px) {
	.mobile-navigation .navigation-list.navigation-list--large {
		font-size: 1.25rem;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.mobile-navigation .navigation-list.navigation-list--large {
		font-size: 1.5rem;
	}
}

.navigation-list + .navigation-list {
	margin-top: var(--space-x-small);
}

.navigation-list__item {
	margin: 0;
	position: relative;
}

.navigation-list--horizontal.navigation-list--separated .navigation-list__item:not(:last-child) {
	padding-right: 0.4375rem;
	position: relative;
}

.navigation-list--horizontal.navigation-list--separated .navigation-list__item:not(:last-child)::after {
	content: "-";
	position: absolute;
	top: 0;
	right: 0;
}

.navigation-list__link,
.navigation-list__note {
	box-sizing: border-box;
	color: currentColor;
	display: inline-block;
	font-size: inherit;
	line-height: inherit;
	margin: 0;
	text-decoration: none;
}

@media (max-width: 767px) {
	.navigation-list__link,
	.navigation-list__note {
		display: inline-block;
		width: 100%;
	}
}

.navigation-list--horizontal .navigation-list__link,
.navigation-list--horizontal .navigation-list__note {
	padding: 0 0.625rem;
}

.navigation-list--horizontal.navigation-list--small .navigation-list__link,
.navigation-list--horizontal.navigation-list--small .navigation-list__note {
	padding-right: 0.375rem;
	padding-left: 0.375rem;
}

@media (min-width: 1280px) {
	.navigation-list--horizontal.navigation-list--large .navigation-list__link,
	.navigation-list--horizontal.navigation-list--large .navigation-list__note {
		padding-right: 1rem;
		padding-left: 1rem;
	}
}

.navigation-list--horizontal .navigation-list__item:first-child .navigation-list__link,
.navigation-list--horizontal .navigation-list__item:first-child .navigation-list__note {
	padding-left: 0;
}

.navigation-list--horizontal .navigation-list__item:last-child .navigation-list__link,
.navigation-list--horizontal .navigation-list__item:last-child .navigation-list__note {
	padding-right: 0;
}

.navigation-list--compressed .navigation-list__link,
.navigation-list--compressed .navigation-list__note {
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100px;
}

@media (min-width: 1024px) {
	.navigation-list--compressed .navigation-list__link,
	.navigation-list--compressed .navigation-list__note {
		max-width: 150px;
	}
}

@media (min-width: 1280px) {
	.navigation-list--compressed .navigation-list__link,
	.navigation-list--compressed .navigation-list__note {
		max-width: 200px;
	}
}

.navigation-list__link {
	transition: color 96ms linear;
}

.navigation-list__link:hover {
	color: var(--color-red);
	cursor: pointer;
}

.navigation-list__link--search {
	vertical-align: middle;
}

.navigation-list__link .icon-search {
	display: block;
	width: 0.9375rem;
	height: 0.9375rem;
}

.u-colored--cyan .navigation-list__link:hover,
.u-colored--red .navigation-list__link:hover,
.u-colored--yellow .navigation-list__link:hover {
	color: var(--color-blue);
}

.navigation-list__link.site-footer,
.navigation-list__link.navigation-list__link--active,
.navigation-list--horizontal.navigation-list--separated .navigation-list__link {
	text-decoration: underline;
}

.u-color-detailed--blue .navigation-list__link.navigation-list__link--active,
.u-color-detailed--pink .navigation-list__link.navigation-list__link--active {
	color: var(--color-blue);
}

.u-color-detailed--cyan .navigation-list__link.navigation-list__link--active,
.u-color-detailed--red .navigation-list__link.navigation-list__link--active,
.u-color-detailed--yellow .navigation-list__link.navigation-list__link--active {
	color: var(--color-red);
}

.site-header.u-color-detailed--blue .site-header__navigation .navigation-list__link,
.site-header.u-color-detailed--red .site-header__navigation .navigation-list__link:hover {
	color: var(--color-blue);
}

.site-header.u-color-detailed--red .site-header__navigation .navigation-list__link,
.site-header.u-color-detailed--blue .site-header__navigation .navigation-list__link:hover {
	color: var(--color-red);
}

.site-header__meta .navigation-list__link,
.site-header__meta .navigation-list__link.navigation-list__link--active {
	color: black;
}

.doormat-siblings .navigation-list__note {
	color: var(--color-grey);
	margin-bottom: 0.5rem;
}

/* #region Sub Menu Trigger */
.navigation-list__submenu-trigger {
	display: inline-flex;
}

.navigation-list__item:hover .navigation-list__submenu-trigger {
	color: var(--color-red);
}

.site-header:is(.u-colored--red, .u-colored--cyan, .u-colored--yellow) .navigation-list__item:hover .navigation-list__submenu-trigger {
	color: var(--color-blue);
}

.navigation-list__item:not(:last-child) .navigation-list__submenu-trigger {
	padding-right: 0.625rem;
}

.navigation-list__submenu-trigger .navigation-list__link.navigation-list__link--submenu-trigger {
	padding-right: 0.5rem;
}

.navigation-list__submenu-trigger__button {
	-mrh-resets: button;
}

.navigation-list__submenu-trigger__button .icon {
	color: currentColor;
	display: block;
	transition: color 96ms linear;
	width: 1.25rem;
	height: 1.25rem;
}

.navigation-list__submenu-trigger__button:hover .icon {
	color: currentColor;
}
/* #endregion Sub Menu Trigger */

/* #region Sub Menu */
.navigation-list__submenu {
	box-sizing: border-box;
	display: none;
	padding-top: 0.5rem;
	position: absolute;
	text-transform: lowercase;
	top: 100%;
	left: 0.625rem;
	width: max-content;
	min-width: 100%;
	z-index: 97;
}

@media (min-width: 1280px) {
	.navigation-list__submenu {
		left: 1rem;
	}
}

.navigation-list__submenu__list {
	background-color: var(--color-white);
	border-color: var(--color-red);
	border-style: solid;
	border-width: 2px;
	font-family: var(--font-sans);
	font-size: 1rem;
	line-height: 1.8125rem;
	list-style: none;
	margin: 0;
	padding-inline: 0.625rem;
	padding-block: 0.625rem;
}

.site-header:is(.u-colored--red, .u-colored--cyan, .u-colored--yellow) .navigation-list__submenu__list {
	border-color: var(--color-blue);
}

.navigation-list__submenu__list__item:not(:last-child) {
	margin-bottom: 0.375rem;
}

.navigation-list__submenu__link {
	color: var(--color-red);
	cursor: pointer;
	transition: color 96ms linear;
}

.site-header:is(.u-colored--red, .u-colored--cyan, .u-colored--yellow) .navigation-list__submenu__link {
	color: var(--color-blue);
}

.navigation-list__submenu__link--active,
.navigation-list__submenu__link:hover {
	text-decoration: none;
}

.navigation-list__item:hover .navigation-list__submenu,
.navigation-list__submenu[aria-expanded="true"] {
	display: block;
}

/* #endregion Sub Menu */

/* #region Mobile Menu Trigger */
.navigation-list__mobile-menu__trigger-wrapper {
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.navigation-list__mobile-menu__trigger-wrapper:has([aria-expanded="true"]) {
	color: var(--color-blue);
}

.navigation-list__mobile-menu__trigger {
	-mrh-resets: button;
	margin-left: 0.5rem;
}

.navigation-list__mobile-menu__trigger .icon {
	display: block;
	pointer-events: none;
	width: 0.9375rem;
	height: 0.9375rem;
}
/* #endregion Mobile Menu Trigger */

/* #region Mobile Menu Panel */
.navigation-list__mobile-menu__panel[hidden] {
	display: none;
}

@supports (content-visibility: hidden) {
	.navigation-list__mobile-menu__panel[hidden="until-found"] {
		content-visibility: hidden;
		display: block;
	}
}

.navigation-list__mobile-menu__panel__list {
	background-color: var(--color-white);
	border: 2px solid var(--color-blue);
	box-sizing: border-box;
	font-size: 0.875rem;
	line-height: 1.375rem;
	list-style: none;
	margin-inline: auto;
	margin-block: 0.5rem;
	padding: 0.625rem;
	text-align: left;
	width: max-content;
	max-width: 100%;
}

.navigation-list__mobile-menu__panel__list__item:not(:last-child) {
	margin-bottom: 0.625rem;
}

.navigation-list__mobile-menu__panel__link {
	color: var(--color-blue);
}

.navigation-list__mobile-menu__panel__link--active,
.navigation-list__mobile-menu__panel__link:hover {
	text-decoration: none;
}
/* #endregion Mobile Menu Panel */
