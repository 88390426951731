.call-to-action-group {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	position: relative;
}

@media (--mq-from-small) {
	.call-to-action-group:not(.call-to-action-group--fill):not(.call-to-action-group--error)::after {
		background-image: url("../images/dot.svg");
		background-repeat: repeat-x;
		background-size: 4px 1px;
		content: "";
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: 1px;
		z-index: 1;
	}
}
