.team-grid {
}

.team-grid__people {
	display: grid;
	grid-row-gap: 2.5rem;
}

/* mq-from-medium */
@media (--mq-from-medium) {
	.team-grid__people {
		grid-row-gap: 3.125rem;
		grid-column-gap: 50px;
		grid-template-columns: repeat(2, 1fr);
	}
}

/* mq-from-large */
@media (--mq-from-large) {
	.team-grid__people {
		grid-column-gap: 130px;
	}
}

.team-grid__person__image-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.team-grid__people .team-grid__person:nth-child(4n + 1) .team-grid__person__image-container {
	background-color: var(--color-pink);
}

.team-grid__people .team-grid__person:nth-child(4n + 2) .team-grid__person__image-container {
	background-color: var(--color-blue);
}

.team-grid__people .team-grid__person:nth-child(4n + 3) .team-grid__person__image-container {
	background-color: var(--color-cyan);
}

.team-grid__people .team-grid__person:nth-child(4n) .team-grid__person__image-container {
	background-color: var(--color-red);
}

.team-grid__person__image {
	border-radius: 99999px;
	box-sizing: border-box;
	padding-inline: 27px;
	padding-block: 1.6875rem;
	width: auto;
	max-width: 100%;
	height: 100%;
	z-index: 1;
}

.team-grid__person__info {
	display: flex;
	flex-direction: column;
	margin-block-start: 1.25rem;
}

.team-grid__person__name {
	font-weight: 300;
	margin-block-end: 10px;
}

.team-grid__person__job-description,
.team-grid__person__email {
	font-size: 0.875rem; /* 14px */
	font-weight: 500;
	line-height: 1.375rem; /* 22 */
	margin: 0;
}

.team-grid__person__email {
	color: var(--color-red);
	text-decoration: none;
}
