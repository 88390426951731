.page-header {
}

@media (min-width: 1024px) {
	.page-header__wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;
	}
}

.page-header__back-link {
	padding-bottom: 1rem;
	width: 100%;
}

@media (min-width: 768px) and (max-width: 1023px) {
	.page-header__back-link {
		box-sizing: border-box;
		padding-right: 12.5%;
		padding-left: 12.5%;
	}
}

@media (min-width: 1024px) {
	.page-header__back-link {
		flex-basis: 100%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (max-width: 1279px) {
	.page-header__sidebar {
		display: none;
	}
}

@media (min-width: 1280px) {
	.page-header__sidebar {
		flex-basis: 21.875%;
		flex-grow: 0;
		flex-shrink: 0;
		padding-top: 0.125rem;
	}
}

@media (min-width: 1680px) {
	.page-header__sidebar {
		flex-basis: calc((100% - 692px) / 2);
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.page-header__main {
		padding-right: 12.5%;
		padding-left: 12.5%;
	}
}

@media (min-width: 1024px) {
	.page-header__main {
		flex-basis: 75%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

@media (min-width: 1280px) {
	.page-header__main {
		flex-basis: 56.25%;
	}
}

@media (min-width: 1680px) {
	.page-header__main {
		flex-basis: 692px;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.page-header__main:only-child {
		padding-right: 25%;
	}
}

@media (min-width: 1024px) {
	.page-header__main:only-child {
		flex-basis: 62.5%;
	}
}

@media (min-width: 1280px) {
	.page-header__main:only-child {
		flex-basis: 41.625%;
	}
}

@media (min-width: 1680px) {
	.page-header__main:only-child {
		flex-basis: 552px;
	}
}

.page-header__title {
	color: black;
	font-size: 1.375rem;
	line-height: 1.3333;
	margin-bottom: var(--space-x-small);
}

@media (min-width: 768px) {
	.page-header__title {
		font-size: 2rem;
		margin-bottom: var(--space-small);
	}
}

@media (min-width: 1380px) {
	.page-header__title {
		font-size: 2.25rem;
		margin-bottom: var(--space-medium);
	}
}

.page-header__content {
	font-size: 1.0625rem;
	line-height: 1.6;
}

@media (min-width: 768px) {
	.page-header__content {
		font-size: 1.125rem;
	}
}

@media (min-width: 1380px) {
	.page-header__content {
		font-size: 1.25rem;
	}
}

.page-header__content p {
	color: currentColor;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
}

.page-header__content p:not(:last-child) {
	margin-bottom: 1.5rem;
}

.page-header__content ul:not([class]) {
	padding: 0;
}

.page-header__content ul:not([class]) li:not([class]) {
	padding: 0;
}

.page-header__content ul:not([class]) li:not([class])::after,
.page-header__content ul:not([class]) li:not([class])::before {
	content: none;
	display: none;
}

.page-header__content ul:not([class]) a:not([class]) {
	color: black;
	padding-left: 1.25rem;
	position: relative;
	text-decoration: none;
}

.u-color-detailed--blue .page-header__content ul:not([class]) a:not([class]):hover,
.u-color-detailed--pink .page-header__content ul:not([class]) a:not([class]):hover {
	color: var(--color-blue);
}

.u-color-detailed--cyan .page-header__content ul:not([class]) a:not([class]):hover,
.u-color-detailed--red .page-header__content ul:not([class]) a:not([class]):hover,
.u-color-detailed--yellow .page-header__content ul:not([class]) a:not([class]):hover {
	color: var(--color-red);
}

.page-header__content ul:not([class]) a:not([class])::after,
.page-header__content ul:not([class]) a:not([class])::before {
	border-radius: 0;
	content: "";
	display: block;
	position: absolute;
	top: 0.5rem;
	transform: rotate(45deg);
	width: 0.375rem;
	height: 0.375rem;
}

.page-header__content ul:not([class]) a:not([class])::after {
	background-color: white;
	left: -0.125rem;
	z-index: 2;
}

.page-header__content ul:not([class]) a:not([class])::before {
	background-color: currentColor;
	left: 0;
	z-index: 1;
}
