.modal {
	-webkit-overflow-scrolling: touch;
	background-color: rgb(200 236 237 / 0.97);
	box-sizing: border-box;
	display: none;
	opacity: 0;
	overflow-x: hidden;
	overflow-y: scroll;
	padding: 5rem 6.25% 6.25vw;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: opacity 96ms linear;
	z-index: 99;
}

@media (--mq-from-small) {
	.modal {
		padding: 6rem 10% 10vw;
	}
}

@media (min-width: 1280px) {
	.modal {
		padding: 7.125rem 0 0;
	}
}

.modal.is-ready {
	display: block;
}

.modal.is-showing {
	opacity: 1;
}

.modal.modal--slideshow {
	padding-bottom: 2rem;
}

@media (--mq-up-to-large) {
	.modal.modal--slideshow {
		padding-right: 6.25%;
		padding-left: 6.25%;
	}
}

@media (--mq-from-large) {
	.modal.modal--slideshow {
		padding-right: calc((100% - 1460px) / 2);
		padding-left: calc((100% - 1460px) / 2);
	}
}

.modal__close {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	color: var(--color-red);
	display: inline-block;
	font-size: 0.875rem;
	margin: 0;
	outline: 0;
	padding: 0;
	position: absolute;
	text-align: right;
	text-decoration: underline;
	top: 2.25rem;
	right: 6.25%;
	transition: color 96ms linear;
}

@media (--mq-from-small) {
	.modal__close {
		top: 3.25rem;
		right: 10%;
	}
}

@media (min-width: 1280px) {
	.modal__close {
		top: 3.625rem;
		right: calc((100% - 944px) / 2);
	}
}

.modal__close:hover {
	color: var(--color-blue);
	cursor: pointer;
}

.u-color-detailed--blue .modal__close,
.u-color-detailed--pink .modal__close {
	color: var(--color-blue);
}

.u-color-detailed--blue .modal__close:hover,
.u-color-detailed--pink .modal__close:hover {
	color: var(--color-red);
}

@media (--mq-up-to-large) {
	.modal--slideshow .modal__close {
		right: 6.25%;
	}
}

@media (--mq-from-large) {
	.modal--slideshow .modal__close {
		right: calc((100% - 1460px) / 2);
	}
}

.modal__body {
	background-color: white;
	box-sizing: border-box;
	padding: 6.25vw;
	transform: scale(0.95);
	transition: transform 720ms cubic-bezier(0.25, 0, 0.11, 1);
	width: 100%;
}

@media (--mq-from-small) {
	.modal__body {
		padding: var(--space-medium) var(--space-x-large) var(--space-x-large);
	}
}

@media (min-width: 1280px) {
	.modal__body {
		margin-right: auto;
		margin-left: auto;
		transform: scale(0.97);
		width: 944px;
	}
}

@media (--mq-from-wide) {
	.modal__body {
		padding: 5rem calc((944px - 45vw) / 2) calc((944px - 45vw) / 2);
	}
}

@media (--mq-from-large) {
	.modal__body {
		padding: 5.625rem 126px 126px;
		transform: scale(0.98);
	}
}

.is-showing .modal__body {
	transform: scale(1);
}

.modal--slideshow .modal__body {
	background-color: transparent;
	margin: 0;
	padding: 0;
	width: 100%;
}
