.accordion {
	display: block;
	overflow: hidden;
	width: 100%;
}

.accordion__item {
	overflow: hidden;
}

.accordion__item:not(:last-child) {
	border-bottom: 1px solid var(--color-cyan);
	box-sizing: border-box;
}

/* blue */

.u-color-detailed--blue .accordion__item:not(:last-child) {
	border-bottom-color: var(--color-pink);
}

/* cyan */

.u-color-detailed--cyan .accordion__item:not(:last-child) {
	border-bottom-color: var(--color-red);
}

/* pink */

.u-color-detailed--pink .accordion__item:not(:last-child) {
	border-bottom-color: var(--color-blue);
}

/* yellow */

.u-color-detailed--yellow .accordion__item:not(:last-child) {
	border-bottom-color: var(--color-red);
}

.accordion__item__container {
	background-color: var(--color-red);
	color: white;
}

.accordion__item__checkbox:checked + .accordion__item__container {
	background-color: var(--color-cyan);
	color: var(--color-red);
}

/* blue */

.u-color-detailed--blue .accordion__item__container {
	background-color: var(--color-blue);
	color: white;
}

.u-color-detailed--blue .accordion__item__checkbox:checked + .accordion__item__container {
	background-color: var(--color-pink);
	color: var(--color-blue);
}

/* cyan */

.u-color-detailed--cyan .accordion__item__container {
	background-color: var(--color-cyan);
	color: var(--color-red);
}

.u-color-detailed--cyan .accordion__item__checkbox:checked + .accordion__item__container {
	background-color: var(--color-red);
	color: var(--color-cyan);
}

/* pink */

.u-color-detailed--pink .accordion__item__container {
	background-color: var(--color-pink);
	color: white;
}

.u-color-detailed--pink .accordion__item__checkbox:checked + .accordion__item__container {
	background-color: var(--color-blue);
	color: var(--color-pink);
}

/* yellow */

.u-color-detailed--yellow .accordion__item__container {
	background-color: var(--color-yellow);
	color: var(--color-red);
}

.u-color-detailed--yellow .accordion__item__checkbox:checked + .accordion__item__container {
	background-color: var(--color-red);
	color: white;
}

.accordion__item__header {
	box-sizing: border-box;
	display: block;
	font-size: 1.0625rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.275;
	padding: 1rem;
	position: relative;
	user-select: none;
	width: 100%;
}

@media (min-width: 768px) {
	.accordion__item__header {
		font-size: 1.25rem;
	}
}

@media (min-width: 1024px) {
	.accordion__item__header {
		padding: 1.5rem 2.5rem 1.5rem 1.5rem;
		text-decoration-color: rgb(255 255 255 / 0);
		text-decoration-line: underline;
		transition: text-decoration-color 128ms linear;
	}
}

.accordion__item__header:hover {
	cursor: pointer;
}

@media (min-width: 1024px) {
	.accordion__item__header:hover {
		text-decoration-color: currentColor;
	}
}

.accordion__item__header::after {
	content: "+";
	position: absolute;
	text-align: center;
	top: 50%;
	right: 1rem;
	transform: translateY(-50%);
	width: 1.5rem;
}

@media (min-width: 1024px) {
	.accordion__item__header::after {
		right: 1.5rem;
	}
}

.accordion__item__checkbox:checked + .accordion__item__container > .accordion__item__header::after {
	content: "-";
}

/* cyan */

.u-color-detailed--cyan .accordion__item__checkbox:checked + .accordion__item__container > .accordion__item__header {
	color: white;
}

/* pink */

.u-color-detailed--pink .accordion__item__checkbox:not(:checked) + .accordion__item__container > .accordion__item__header {
	color: var(--color-blue);
}

.u-color-detailed--pink .accordion__item__checkbox:checked + .accordion__item__container > .accordion__item__header {
	color: white;
}

.accordion__item__content {
	box-sizing: border-box;
	color: black;
	opacity: 0;
	padding-right: 1rem;
	padding-left: 1rem;
	height: 0;
}

/* cyan + pink + yellow */

.u-color-detailed--cyan .accordion__item__content,
.u-color-detailed--pink .accordion__item__content,
.u-color-detailed--yellow .accordion__item__content {
	color: white;
}

.accordion__item__content p > a:not([class]) {
	color: currentColor;
	font-weight: var(--font-weight-bold);
}

.accordion__item__content p > a:not([class]):hover {
	color: var(--color-red);
}

/* blue */

.u-color-detailed--blue .accordion__item__content p > a:not([class]):hover {
	color: var(--color-blue);
}

/* cyan */

.u-color-detailed--cyan .accordion__item__content p > a:not([class]):hover {
	color: var(--color-cyan);
}

/* pink */

.u-color-detailed--pink .accordion__item__content p > a:not([class]):hover {
	color: var(--color-pink);
}

/* yellow */

.u-color-detailed--yellow .accordion__item__content p > a:not([class]):hover {
	color: var(--color-yellow);
}

@media (min-width: 1024px) {
	.accordion__item__content {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}
}

.accordion__item__checkbox:checked + .accordion__item__container > .accordion__item__content {
	opacity: 1;
	padding-top: 0.25rem;
	padding-bottom: 2rem;
	height: auto;
}

@media (min-width: 1024px) {
	.accordion__item__checkbox:checked + .accordion__item__container > .accordion__item__content {
		padding-top: 0.75rem;
		padding-bottom: 2.5rem;
	}
}
