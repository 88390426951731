.section {
	padding-top: var(--space-x-small);
	padding-bottom: var(--space-x-small);
	position: relative;
}

@media (--mq-from-small) {
	.section {
		padding-top: var(--space-small);
		padding-bottom: var(--space-small);
	}
}

@media (--mq-from-medium) {
	.section {
		padding-top: var(--space-medium);
		padding-bottom: var(--space-medium);
	}
}

@media (--mq-from-wide) {
	.section {
		padding-top: var(--space-large);
		padding-bottom: var(--space-large);
	}
}

@media (--mq-from-large) {
	.section {
		padding-top: var(--space-x-large);
		padding-bottom: var(--space-x-large);
	}
}

.section.section--small {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

@media (--mq-from-small) {
	.section.section--small {
		padding-top: var(--space-x-small);
		padding-bottom: var(--space-x-small);
	}
}

@media (--mq-from-wide) {
	.section.section--small {
		padding-top: var(--space-small);
		padding-bottom: var(--space-small);
	}
}

.section.section--border-bottom::after,
.section.section--border-top::before {
	background-image: url("../images/dot.svg");
	background-repeat: repeat-x;
	background-size: 4px 1px;
	content: "";
	position: absolute;
	left: 0;
	width: 100%;
	height: 1px;
}

.section.section--border-bottom::after {
	bottom: 0;
}

.section.section--border-top::before {
	top: 0;
}

.section--border-bottom + .section.section--border-top::before {
	content: none;
}

.section:not(.section--border-bottom):not(.u-colored) + .section:not(.section--border-top):not(.u-colored) {
	padding-top: 0;
}
