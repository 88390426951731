@media (--mq-from-small) {
	.pagination {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.pagination-link {
	color: black;
	transition: color 96ms linear;
}

.pagination-link:hover {
	color: var(--color-red);
}

@media (--mq-up-to-small) {
	.pagination-link:not(:last-child):not(:only-child) {
		margin-bottom: 1rem;
	}

	.pagination-link--previous ~ .pagination-link--next {
		margin-left: 1rem;
	}
}

@media (--mq-from-small) {
	.pagination-link {
		margin-right: 1rem;
		margin-left: 1rem;
	}
}
