.text-banner {
	color: currentColor;
	font-size: 1.25rem;
	font-weight: var(--font-weight-normal);
	line-height: 1.5;
}

@media (--mq-from-small) {
	.text-banner {
		font-size: 1.375rem;
		line-height: 2;
	}
}

@media (min-width: 1280px) {
	.text-banner {
		font-size: 2.25rem;
	}
}

.text-banner.text-banner--small {
	font-size: 1.125rem;
	line-height: 1.5;
}

@media (min-width: 1280px) {
	.text-banner.text-banner--small {
		font-size: 1.25rem;
	}
}

@media (--mq-from-wide) {
	.text-banner.text-banner--small {
		font-size: 1.5rem;
	}
}

.text-banner.text-banner--dense {
	line-height: 1.25;
}

.text-banner a,
.text-banner button,
.text-banner p,
.text-banner span {
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
}
