.page-intro {
}

@media (min-width: 1024px) {
	.page-intro__wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.page-intro__main {
		padding-right: 12.5%;
		padding-left: 12.5%;
	}
}

@media (min-width: 1024px) {
	.page-intro__main {
		flex-basis: 62.5%;
	}
}

@media (min-width: 1280px) {
	.page-intro__main {
		flex-basis: 41.625%;
	}
}

@media (min-width: 1680px) {
	.page-intro__main {
		flex-basis: 552px;
	}
}

@media (max-width: 1023px) {
	.page-intro__main:not(:only-child) {
		margin-bottom: 2rem;
	}
}

.page-intro__content {
	font-size: 1.0625rem;
	line-height: 1.6;
}

@media (min-width: 768px) {
	.page-intro__content {
		font-size: 1.125rem;
	}
}

@media (min-width: 1380px) {
	.page-intro__content {
		font-size: 1.25rem;
	}
}

.page-intro__content p {
	color: currentColor;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
}

.page-intro__content p:not(:last-child) {
	margin-bottom: 1.5rem;
}

@media (min-width: 768px) and (max-width: 1023px) {
	.page-intro__meta {
		padding-right: 12.5%;
		padding-left: 12.5%;
	}
}

@media (min-width: 1024px) {
	.page-intro__meta {
		flex-basis: 33.3333%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.u-color-detailed--blue .page-intro__meta,
.u-color-detailed--pink .page-intro__meta {
	color: var(--color-blue);
}

.u-color-detailed--cyan .page-intro__meta,
.u-color-detailed--red .page-intro__meta,
.u-color-detailed--yellow .page-intro__meta {
	color: var(--color-red);
}

.page-intro__meta ul:not([class]) {
	padding: 0;
}

.page-intro__meta ul:not([class]) li:not([class]) {
	padding: 0;
}

.page-intro__meta ul:not([class]) li:not([class])::after,
.page-intro__meta ul:not([class]) li:not([class])::before {
	content: none;
	display: none;
}

.page-intro__meta__item {
	color: inherit;
	padding-left: 1.25rem;
	position: relative;
	text-decoration: none;
}

.u-color-detailed--cyan a.page-intro__meta__item:hover,
.u-color-detailed--red a.page-intro__meta__item:hover,
.u-color-detailed--yellow a.page-intro__meta__item:hover {
	color: var(--color-blue);
}

.u-color-detailed--blue a.page-intro__meta__item:hover,
.u-color-detailed--pink a.page-intro__meta__item:hover {
	color: var(--color-red);
}

.page-intro__meta__item::after,
.page-intro__meta__item::before {
	border-radius: 0;
	content: "";
	display: block;
	position: absolute;
	top: 50%;
	transform: rotate(45deg) translateY(-50%);
	width: 0.375rem;
	height: 0.375rem;
}

.page-intro__meta__item::after {
	background-color: white;
	left: -0.1875rem;
	z-index: 2;
}

.page-intro__meta__item::before {
	background-color: currentColor;
	left: 0;
	z-index: 1;
}
