@media (--mq-from-small) {
	.windows {
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
	}
}

@media (--mq-from-small) {
	.windows__item {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}
