.date-period {
	display: flex;
	justify-content: center;
	text-align: center;
	width: 100%;
}

.days-30 #day__31 {
	display: none;
}

.days-29 #day__31,
.days-29 #day__30 {
	display: none;
}

.days-28 #day__31,
.days-28 #day__30,
.days-28 #day__29 {
	display: none;
}
