@media (--mq-from-medium) {
	.image-group {
		display: flex;
		justify-content: space-between;
	}
}

@media (--mq-from-small) {
	.image-group__item {
		box-sizing: border-box;
	}
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.image-group__item {
		padding-right: var(--space-small);
		padding-left: var(--space-small);
	}
}

@media (--mq-from-medium) {
	.image-group__item {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
		padding-right: var(--space-small);
		padding-left: var(--space-small);
	}
}

@media (--mq-from-wide) {
	.image-group__item {
		padding-right: var(--space-medium);
		padding-left: var(--space-medium);
	}
}

@media (--mq-from-large) {
	.image-group__item {
		padding-right: var(--space-large);
		padding-left: var(--space-large);
	}
}

@media (--mq-up-to-small) {
	.image-group__item:not(:last-child) {
		margin-bottom: var(--space-x-small);
	}
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.image-group__item:not(:last-child) {
		margin-bottom: var(--space-small);
	}
}

.image-group__item .image-group__item {
	margin: 0;
	padding: 0;
}
