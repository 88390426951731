.call-to-action {
	background-color: var(--color-blue);
	box-sizing: border-box;
	color: white;
	display: flex;
	flex-basis: 87.5%;
	flex-grow: 0;
	flex-shrink: 0;
	flex-direction: column;
	padding: 1.5rem;
	justify-content: center;
	align-items: center;
	position: relative;
}

@media (min-width: 768px) {
	.call-to-action {
		flex-basis: 45%;
		font-size: 1.125rem;
		padding: var(--space-small);
		z-index: 3;
	}
}

@media (min-width: 1024px) {
	.call-to-action {
		flex-basis: 33.3333%;
		min-height: 20vw;
	}
}

@media (min-width: 1380px) {
	.call-to-action {
		flex-basis: 30%;
		padding: var(--space-medium);
		min-height: 18vw;
	}
}

@media (min-width: 1680px) {
	.call-to-action {
		flex-basis: 552px;
		min-height: 330px;
	}
}

@media (min-width: 768px) {
	.call-to-action:only-child {
		font-size: 1.125rem;
	}
}

.call-to-action-group--fill .call-to-action {
	flex-basis: 100%;
	flex-grow: 1;
	justify-content: flex-start;
	align-items: flex-start;
}

@media (min-width: 1024px) {
	.call-to-action-group--fill .call-to-action {
		flex-basis: 33.3333%;
	}
}

@media (max-width: 767px) {
	.doormat .call-to-action {
		min-height: auto;
	}
}

@media (min-width: 768px) {
	.doormat .call-to-action {
		font-size: 1rem;
		text-align: center;
		height: 350px;
	}
}

@media (min-width: 1680px) {
	.doormat .call-to-action {
		flex-basis: 506px;
		min-height: 304px;
	}
}

.call-to-action-group--error .call-to-action {
	background-color: var(--color-grey-light);
	color: var(--color-blue);
}

.call-to-action-group--error .call-to-action > *:first-child {
	padding-top: 3rem;
	position: relative;
}

.call-to-action-group--error .call-to-action > *:first-child::before {
	border: 1px solid var(--color-blue);
	border-radius: 50%;
	box-sizing: border-box;
	color: var(--color-blue);
	content: "!";
	display: block;
	font-size: 1rem;
	line-height: 1.125rem;
	position: absolute;
	text-align: center;
	top: 0;
	left: 0;
	width: 1.25rem;
	height: 1.25rem;
}

.call-to-action a:not([class]) {
	color: currentColor;
}

.call-to-action.u-colored--cyan a:not([class]):hover,
.call-to-action.u-colored--red a:not([class]):hover,
.call-to-action.u-colored--yellow a:not([class]):hover {
	color: var(--color-blue);
}

.call-to-action__content {
	width: 100%;
}

.call-to-action__content:not(:only-child) {
	margin-top: var(--space-x-small);
}

@media (min-width: 1280px) {
	.call-to-action__content:not(:only-child) {
		margin-top: var(--space-small);
	}
}

.call-to-action__content p:not(:last-child) {
	margin-bottom: var(--space-x-small);
	width: 100%;
}

@media (min-width: 1280px) {
	.call-to-action__content p:not(:last-child) {
		margin-bottom: var(--space-small);
	}
}

@media (max-width: 767px) {
	.call-to-action__checkbox ~ .call-to-action__content {
		display: none;
	}
}

@media (max-width: 1023px) {
	.call-to-action-group--fill .call-to-action__checkbox ~ .call-to-action__content {
		display: none;
	}
}

.call-to-action__checkbox:checked ~ .call-to-action__content {
	display: block;
}

.call-to-action__title {
	display: inline-block;
	margin: 0;
}

@media (min-width: 768px) {
	.call-to-action__title {
		font-size: 1.25rem;
	}
}

@media (min-width: 768px) {
	.doormat .call-to-action__title {
		font-size: 1.375rem;
	}
}

.call-to-action.u-colored--blue .call-to-action__title,
.call-to-action.u-colored--red .call-to-action__title {
	color: white;
}

.call-to-action.u-colored--cyan .call-to-action__title,
.call-to-action.u-colored--yellow .call-to-action__title {
	color: var(--color-red);
}

.call-to-action.u-colored--pink .call-to-action__title {
	color: var(--color-blue);
}

.call-to-action__checkbox ~ .call-to-action__title {
	box-sizing: border-box;
	display: block;
	position: relative;
	width: 100%;
}

.call-to-action__checkbox ~ .call-to-action__title::after {
	color: currentColor;
	content: "+";
	display: none;
	position: absolute;
	text-align: center;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	width: 1.5rem;
}

@media (max-width: 767px) {
	.call-to-action__checkbox ~ .call-to-action__title::after {
		display: block;
	}
}

@media (max-width: 1023px) {
	.call-to-action-group--fill .call-to-action__checkbox ~ .call-to-action__title::after {
		display: block;
	}
}

.call-to-action__checkbox:checked ~ .call-to-action__title::after {
	content: "-";
}

@media (max-width: 767px) {
	.call-to-action__checkbox ~ .call-to-action__title:hover {
		cursor: pointer;
	}
}

@media (max-width: 1023px) {
	.call-to-action-group--fill .call-to-action__checkbox ~ .call-to-action__title:hover {
		cursor: pointer;
	}
}

@media (max-width: 1023px) {
	.call-to-action__label {
		display: block;
		width: calc(100% - 1.375rem);
	}
}

@media (max-width: 767px) {
	.call-to-action__label {
		user-select: none;
	}
}

@media (max-width: 767px) {
	.call-to-action__checkbox ~ .call-to-action__title .call-to-action__label {
		cursor: pointer;
	}
}

@media (max-width: 1023px) {
	.call-to-action-group--fill .call-to-action__checkbox ~ .call-to-action__title .call-to-action__label {
		cursor: pointer;
	}
}
