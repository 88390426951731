.mobile-navigation {
	-webkit-overflow-scrolling: touch;
	background-color: var(--color-cyan);
	color: var(--color-red);
	display: none;
	overflow-x: hidden;
	overflow-y: scroll;
	position: fixed;
	text-align: center;
	top: 77px;
	right: 0;
	left: 0;
	height: calc(100vh - 77px);
	z-index: 98;
}

@media (--mq-from-small) {
	.mobile-navigation {
		top: 124px;
		height: calc(100vh - 124px);
	}
}

@media (--mq-up-to-medium) {
	#mobile-navigation-checkbox:checked ~ .mobile-navigation {
		display: block;
	}
}

.l-styleguide .mobile-navigation {
	position: relative;
	top: auto;
}

.mobile-navigation__wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100%;
}

.mobile-navigation__languages {
	padding-top: 1rem;
}

.mobile-navigation__secondary-navigation {
	flex-grow: 1;
}
