/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.slideshow {
	position: relative;
}

.slideshow__wrapper {
	overflow: hidden;
	position: relative;
}

.slideshow__track {
	box-sizing: border-box;
	display: flex;
	flex-wrap: nowrap;
	overflow: hidden;
	transition: transform 688ms cubic-bezier(0.65, 0, 0.265, 1);
	/* stylelint-disable-next-line declaration-property-value-no-unknown -- broken, must be fixed by a human */
	white-space: wrap;
}

mr-slideshow.is-resolved .slideshow__track {
	overflow: visible;
}

@media (--mq-from-wide) {
	.modal .slideshow__track {
		transition-duration: 800ms;
	}
}

.slideshow__item {
	flex: 0 0 100%;
}

mr-slideshow[use-click="true"] .slideshow__item:hover {
	cursor: pointer;
}

.slideshow__navigation {
	background-color: white;
	font-size: 0.875rem;
	position: absolute;
	text-align: right;
	right: 0;
	bottom: 0;
}

.slideshow__navigation::before {
	background: linear-gradient(to right, rgb(255 255 255 / 0) 0%, rgb(255 255 255 / 1) 100%);
	content: "";
	position: absolute;
	top: 0;
	left: -2rem;
	width: 2rem;
	height: 1.5rem;
}

.modal .slideshow__navigation {
	background-color: rgb(202 237 238 / 1);
	color: var(--color-red);
}

.modal .slideshow__navigation::before {
	background: linear-gradient(to right, rgb(202 237 238 / 0) 0%, rgb(202 237 238 / 1) 100%);
}

.slideshow__navigation button {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	color: currentColor;
	display: inline-block;
	margin: 0;
	outline: 0;
	padding: 0 0.25rem;
	text-decoration: none;
}

.slideshow__navigation button:hover {
	cursor: pointer;
}

.slideshow__navigation button.is-active {
	text-decoration: underline;
}
