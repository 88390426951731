.form {
}

.form__section {
	border: 0;
	margin: 0;
	padding: 0;
}

.form__section:not(:last-child) {
	margin-bottom: var(--space-x-small);
}

@media (min-width: 768px) {
	.form__section:not(:last-child) {
		margin-bottom: var(--space-small);
	}
}

@media (min-width: 1024px) {
	.form__section:not(:last-child) {
		margin-bottom: var(--space-medium);
	}
}

@media (min-width: 1380px) {
	.form__section:not(:last-child) {
		margin-bottom: var(--space-large);
	}
}

@media (min-width: 1680px) {
	.form__section:not(:last-child) {
		margin-bottom: var(--space-x-large);
	}
}

.form__section__title {
	font-size: 1.0625rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.275;
	margin: 0 0 2rem;
}

@media (min-width: 768px) {
	.form__section__title {
		font-size: 1.375rem;
	}
}

.u-color-detailed--blue .form__section__title,
.u-color-detailed--pink .form__section__title {
	color: var(--color-blue);
}

.u-color-detailed--cyan .form__section__title,
.u-color-detailed--red .form__section__title,
.u-color-detailed--yellow .form__section__title {
	color: var(--color-red);
}

.form__row,
.mc-field-group,
.acf-form .acf-field {
	padding: 0;
	width: 100%;
}

@media (min-width: 1024px) {
	.form__row,
	.mc-field-group,
	.acf-form .acf-field {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

.form__row:not(:last-child),
.mc-field-group:not(:last-child),
.acf-form .acf-field:not(:last-child) {
	margin-bottom: 1rem;
}

.mc-field-group br {
	display: none;
}

.acf-form .acf-field.acf-error {
	padding-bottom: 1.75rem;
	position: relative;
}

.acf-form .acf-error-message {
	font-size: 0.75rem;
	position: absolute;
	bottom: 0;
	left: 0;
}

@media (min-width: 768px) {
	.acf-form .acf-error-message {
		left: calc(33.3333% - 0.5rem);
	}
}

.acf-form .acf-error-message p {
	font-size: inherit;
}

.acf-form > .acf-error-message {
	display: none;
}

.checkbox-group,
.acf-form .acf-field-checkbox .acf-input,
.radio-group {
	width: 100%;
}

@media (min-width: 768px) {
	.checkbox-group.checkbox-group--columned,
	.checkbox-group.radio-group--columned,
	.acf-form .acf-field-checkbox .acf-input.checkbox-group--columned,
	.acf-form .acf-field-checkbox .acf-input.radio-group--columned,
	.radio-group.checkbox-group--columned,
	.radio-group.radio-group--columned {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}
}

.acf-checkbox-list {
	list-style: none;
	margin: 0 0 2rem;
	padding: 0;
}

.acf-checkbox-list li:not(last-child) {
	margin-bottom: 1rem;
}

.checkbox,
.radio {
	position: relative;
	width: 100%;
}

.filter-bar .checkbox,
.filter-bar .radio {
	width: auto;
}

@media (min-width: 768px) {
	.filter-bar .checkbox + .checkbox,
	.filter-bar .radio + .checkbox {
		margin-left: 1rem;
	}
}

@media (min-width: 768px) {
	.checkbox-group--columned .checkbox,
	.radio-group--columned .checkbox,
	.checkbox-group--columned .radio,
	.radio-group--columned .radio {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
		width: auto;
	}
}

.checkbox:not(:last-child),
.radio:not(:last-child) {
	margin-bottom: 1rem;
}

.checkbox-group--columned .checkbox:not(:last-child),
.radio-group--columned .checkbox:not(:last-child),
.filter-bar .checkbox:not(:last-child),
.label ~ .checkbox-group > .checkbox:not(:last-child),
.label ~ .radio-group > .checkbox:not(:last-child),
.checkbox-group--columned .radio:not(:last-child),
.radio-group--columned .radio:not(:last-child),
.filter-bar .radio:not(:last-child),
.label ~ .checkbox-group > .radio:not(:last-child),
.label ~ .radio-group > .radio:not(:last-child) {
	margin-bottom: 0;
}

.checkbox > input,
.radio > input {
	/* display: none; */
	appearance: none;
	background: red;
	border: 0;
	box-shadow: none;
	opacity: 0;
	outline: 0;
	pointer-events: none;
	position: absolute;
	top: 0.875rem;
	left: 0.375rem;
	width: 1px;
	height: 1px;
	z-index: 0;
}

.checkbox > label,
.radio > label {
	border-radius: 0;
	box-sizing: border-box;
	color: currentColor;
	display: block;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	outline: 0;
	padding: 0 0 0 2rem;
	position: relative;
	user-select: none;
	z-index: 1;
}

.checkbox > label::before,
.radio > label::before {
	border-color: var(--color-grey-light);
	border-style: solid;
	border-width: 2px;
	box-sizing: border-box;
	content: "";
	display: block;
	position: absolute;
	top: 0.5rem;
	left: 0;
	width: 0.75rem;
	height: 0.75rem;
}

.checkbox > label:hover,
.radio > label:hover {
	cursor: pointer;
}

.filter-bar .checkbox > label,
.filter-bar .radio > label {
	color: var(--color-red-contrast);
}

.filter-bar .checkbox > label::before,
.filter-bar .radio > label::before {
	background-color: var(--color-red-contrast);
	border-width: 0;
}

.checkbox > input[type="radio"] + label::before,
.radio > input[type="radio"] + label::before {
	border-radius: 50%;
}

.checkbox > input[type="checkbox"] + label::before,
.radio > input[type="checkbox"] + label::before {
	border-radius: 0;
}

.checkbox > input:checked + label::after,
.checkbox > input:checked + label::before,
.radio > input:checked + label::after,
.radio > input:checked + label::before {
	background-color: black;
	border-color: black;
}

.u-color-detailed--blue .checkbox > input:checked + label::after,
.u-color-detailed--pink .checkbox > input:checked + label::after,
.u-color-detailed--blue .checkbox > input:checked + label::before,
.u-color-detailed--pink .checkbox > input:checked + label::before,
.u-color-detailed--blue .radio > input:checked + label::after,
.u-color-detailed--pink .radio > input:checked + label::after,
.u-color-detailed--blue .radio > input:checked + label::before,
.u-color-detailed--pink .radio > input:checked + label::before {
	background-color: var(--color-blue);
	border-color: var(--color-blue);
}

.u-color-detailed--cyan .checkbox > input:checked + label::after,
.u-color-detailed--red .checkbox > input:checked + label::after,
.u-color-detailed--yellow .checkbox > input:checked + label::after,
.u-color-detailed--cyan .checkbox > input:checked + label::before,
.u-color-detailed--red .checkbox > input:checked + label::before,
.u-color-detailed--yellow .checkbox > input:checked + label::before,
.u-color-detailed--cyan .radio > input:checked + label::after,
.u-color-detailed--red .radio > input:checked + label::after,
.u-color-detailed--yellow .radio > input:checked + label::after,
.u-color-detailed--cyan .radio > input:checked + label::before,
.u-color-detailed--red .radio > input:checked + label::before,
.u-color-detailed--yellow .radio > input:checked + label::before {
	background-color: var(--color-red);
	border-color: var(--color-red);
}

.filter-bar .checkbox > input:checked + label,
.filter-bar .radio > input:checked + label {
	color: white;
}

.filter-bar .checkbox > input:checked + label::before,
.filter-bar .radio > input:checked + label::before {
	background-color: var(--color-red-contrast);
}

.filter-bar .checkbox > input:checked + label::after,
.filter-bar .radio > input:checked + label::after {
	background-color: white;
	border-radius: 50%;
	content: "";
	position: absolute;
	top: 0.75rem;
	left: 0.25rem;
	width: 0.25rem;
	height: 0.25rem;
}

.acf-form .acf-checkbox-list label {
	width: 100%;
}

.acf-form .acf-checkbox-list label > input {
	display: none;
}

.acf-form .acf-checkbox-list label > span {
	border-radius: 0;
	box-sizing: border-box;
	color: currentColor;
	display: block;
	font-size: 1rem;
	line-height: 1.75;
	outline: 0;
	padding: 0 0 0 2rem;
	position: relative;
	user-select: none;
}

.acf-form .acf-checkbox-list label > span::before {
	border-color: var(--color-grey-light);
	border-style: solid;
	border-width: 2px;
	box-sizing: border-box;
	content: "";
	display: block;
	position: absolute;
	top: 0.5rem;
	left: 0;
	width: 0.75rem;
	height: 0.75rem;
}

.acf-form .acf-checkbox-list label > span:hover {
	cursor: pointer;
}

.acf-form .acf-checkbox-list label > input[type="radio"] + span::before {
	border-radius: 50%;
}

.acf-form .acf-checkbox-list label > input:checked + span::after,
.acf-form .acf-checkbox-list label > input:checked + span::before {
	background-color: black;
	border-color: black;
}

.u-color-detailed--blue .acf-form .acf-checkbox-list label > input:checked + span::after,
.u-color-detailed--pink .acf-form .acf-checkbox-list label > input:checked + span::after,
.u-color-detailed--blue .acf-form .acf-checkbox-list label > input:checked + span::before,
.u-color-detailed--pink .acf-form .acf-checkbox-list label > input:checked + span::before {
	background-color: var(--color-blue);
	border-color: var(--color-blue);
}

.u-color-detailed--cyan .acf-form .acf-checkbox-list label > input:checked + span::after,
.u-color-detailed--red .acf-form .acf-checkbox-list label > input:checked + span::after,
.u-color-detailed--yellow .acf-form .acf-checkbox-list label > input:checked + span::after,
.u-color-detailed--cyan .acf-form .acf-checkbox-list label > input:checked + span::before,
.u-color-detailed--red .acf-form .acf-checkbox-list label > input:checked + span::before,
.u-color-detailed--yellow .acf-form .acf-checkbox-list label > input:checked + span::before {
	background-color: var(--color-red);
	border-color: var(--color-red);
}

.input,
.mc-field-group input,
.acf-form input,
.select,
.mc-field-group select,
.acf-form select,
.textarea,
.mc-field-group textarea,
.acf-form textarea {
	background-color: transparent;
	border-color: var(--color-grey-light);
	border-style: solid;
	border-width: 2px;
	border-radius: 0;
	font-family: var(--font-sans);
	font-size: 1rem;
	font-weight: var(--font-weight-normal);
}

.input:active,
.input:focus,
.mc-field-group input:active,
.mc-field-group input:focus,
.acf-form input:active,
.acf-form input:focus,
.select:active,
.select:focus,
.mc-field-group select:active,
.mc-field-group select:focus,
.acf-form select:active,
.acf-form select:focus,
.textarea:active,
.textarea:focus,
.mc-field-group textarea:active,
.mc-field-group textarea:focus,
.acf-form textarea:active,
.acf-form textarea:focus {
	border-color: var(--color-grey);
}

.acf-form .acf-error input,
.acf-form .acf-error select,
.acf-form .acf-error textarea {
	border-color: var(--color-red);
	border-width: 1px;
}

.acf-form .acf-input {
	width: 100%;
}

.input,
.mc-field-group input,
.acf-form input,
.select,
.mc-field-group select,
.acf-form select {
	appearance: none;
	box-shadow: none;
	box-sizing: border-box;
	display: block;
	line-height: 2.875rem;
	outline: 0;
	padding-right: 1rem;
	padding-left: 1rem;
	width: 100%;
	height: 2.875rem;
}

.label,
.mc-field-group label,
.acf-form .acf-label {
	font-size: 1rem;
	line-height: 1.75;
}

@media (max-width: 1023px) {
	.label,
	.mc-field-group label,
	.acf-form .acf-label {
		display: inline-block;
		margin-bottom: 0.25rem;
	}
}

@media (min-width: 1024px) {
	.label,
	.mc-field-group label,
	.acf-form .acf-label {
		box-sizing: border-box;
		flex-basis: calc(33.3333% - 0.5rem);
		flex-grow: 0;
		flex-shrink: 0;
		padding-right: 0.5rem;
	}
}

.filter-bar .input,
.filter-bar .mc-field-group input,
.filter-bar .acf-form input {
	background-color: white;
	border-color: white;
}

@media (min-width: 768px) {
	.filter-bar .input,
	.filter-bar .mc-field-group input,
	.filter-bar .acf-form input {
		width: 20rem;
	}
}

.filter-bar .input:active,
.filter-bar .input:focus,
.filter-bar .mc-field-group input:active,
.filter-bar .mc-field-group input:focus,
.filter-bar .acf-form input:active,
.filter-bar .acf-form input:focus {
	border-color: var(--color-grey);
}

.select,
.mc-field-group select,
.acf-form select {
	background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cG9seWdvbiBwb2ludHM9IjEyLDE4LjcgMCw2LjcgMS41LDUuMyAxMiwxNS44IDIyLjUsNS4zIDI0LDYuNyIvPgo8L2c+Cjwvc3ZnPgo=");
	background-position: calc(100% - 15px) 50%;
	background-repeat: no-repeat;
	background-size: 10px 15px;
}

.filter-bar .select,
.filter-bar .mc-field-group select,
.filter-bar .acf-form select {
	background-color: white;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBvbHlnb24gc3R5bGU9ImZpbGw6I2ZmNDE0NTsiIHBvaW50cz0iMTIsMTguNyAwLDYuNyAxLjUsNS4zIDEyLDE1LjggMjIuNSw1LjMgMjQsNi43Ii8+PC9zdmc+Cg==");
	border-color: white;
	color: red;
}

@media (min-width: 768px) {
	.filter-bar .select,
	.filter-bar .mc-field-group select,
	.filter-bar .acf-form select {
		width: 10rem;
	}
}

.filter-bar .select:active,
.filter-bar .select:focus,
.filter-bar .mc-field-group select:active,
.filter-bar .mc-field-group select:focus,
.filter-bar .acf-form select:active,
.filter-bar .acf-form select:focus {
	border-color: var(--color-grey);
}

@media (min-width: 768px) {
	.filter-bar .select--large {
		width: 20rem;
	}
}

.select--transparent {
	background-color: transparent;
	border: 0 solid transparent;
	padding-right: 2.5rem;
	padding-left: 0;
}

@media (max-width: 767px) {
	.select--transparent {
		padding-right: 2rem;
		padding-left: 0;
	}
}

.select--transparent[disabled] {
	opacity: 0.4;
}

.filter-bar .select--transparent {
	background-color: transparent;
	background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiBmaWxsPSIjZmZmIiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cG9seWdvbiBwb2ludHM9IjEyLDE4LjcgMCw2LjcgMS41LDUuMyAxMiwxNS44IDIyLjUsNS4zIDI0LDYuNyIvPgo8L2c+Cjwvc3ZnPg==");
	color: white;
}

@media (min-width: 768px) {
	.filter-bar .select--transparent {
		width: auto;
	}
}

.filter-bar .select--transparent:active,
.filter-bar .select--transparent:focus {
	border-color: transparent;
}

.filter-bar .select--transparent option {
	color: var(--color-red-contrast);
}

.filter-bar .select--transparent[disabled] {
	background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cG9seWdvbiBwb2ludHM9IjEyLDE4LjcgMCw2LjcgMS41LDUuMyAxMiwxNS44IDIyLjUsNS4zIDI0LDYuNyIvPgo8L2c+Cjwvc3ZnPgo=");
	color: black;
}

.submit,
.newsletter-subscribe input[type="submit"],
.acf-form input[type="submit"] {
	appearance: none;
	background: none;
	background-color: var(--color-grey-light);
	border: 0;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	font-size: 1rem;
	font-weight: var(--font-weight-normal);
	margin: 0;
	outline: 0;
	padding: 1rem 1.5rem;
	transition: background-color 96ms linear;
}

.u-color-detailed--blue .submit,
.u-color-detailed--pink .submit,
.u-color-detailed--blue .newsletter-subscribe input[type="submit"],
.u-color-detailed--pink .newsletter-subscribe input[type="submit"],
.u-color-detailed--blue .acf-form input[type="submit"],
.u-color-detailed--pink .acf-form input[type="submit"] {
	background-color: var(--color-blue);
	color: white;
}

.u-color-detailed--cyan .submit,
.u-color-detailed--red .submit,
.u-color-detailed--yellow .submit,
.u-color-detailed--cyan .newsletter-subscribe input[type="submit"],
.u-color-detailed--red .newsletter-subscribe input[type="submit"],
.u-color-detailed--yellow .newsletter-subscribe input[type="submit"],
.u-color-detailed--cyan .acf-form input[type="submit"],
.u-color-detailed--red .acf-form input[type="submit"],
.u-color-detailed--yellow .acf-form input[type="submit"] {
	background-color: var(--color-red);
	color: white;
}

.filter-bar .submit,
.filter-bar .newsletter-subscribe input[type="submit"],
.filter-bar .acf-form input[type="submit"] {
	background-color: transparent;
	color: white;
	font-weight: var(--font-weight-bold);
	margin-right: auto;
	margin-left: auto;
	padding-right: 0;
	padding-left: 0;
	text-decoration: underline;
	width: auto;
}

.submit:hover,
.newsletter-subscribe input[type="submit"]:hover,
.acf-form input[type="submit"]:hover {
	background-color: var(--color-grey);
	cursor: pointer;
}

.u-color-detailed--blue .submit:hover,
.u-color-detailed--pink .submit:hover,
.u-color-detailed--blue .newsletter-subscribe input[type="submit"]:hover,
.u-color-detailed--pink .newsletter-subscribe input[type="submit"]:hover,
.u-color-detailed--blue .acf-form input[type="submit"]:hover,
.u-color-detailed--pink .acf-form input[type="submit"]:hover {
	background-color: var(--color-red);
}

.u-color-detailed--cyan .submit:hover,
.u-color-detailed--red .submit:hover,
.u-color-detailed--yellow .submit:hover,
.u-color-detailed--cyan .newsletter-subscribe input[type="submit"]:hover,
.u-color-detailed--red .newsletter-subscribe input[type="submit"]:hover,
.u-color-detailed--yellow .newsletter-subscribe input[type="submit"]:hover,
.u-color-detailed--cyan .acf-form input[type="submit"]:hover,
.u-color-detailed--red .acf-form input[type="submit"]:hover,
.u-color-detailed--yellow .acf-form input[type="submit"]:hover {
	background-color: var(--color-blue);
}

.filter-bar .submit:hover,
.filter-bar .newsletter-subscribe input[type="submit"]:hover,
.filter-bar .acf-form input[type="submit"]:hover {
	background-color: transparent;
	color: var(--color-blue);
	transition: color 96ms linear;
}

.acf-form input[type="submit"] {
	padding-top: 0;
	padding-bottom: 0;
}

@media (min-width: 768px) {
	.acf-form input[type="submit"] {
		width: auto;
	}
}

.textarea,
.acf-form textarea {
	appearance: none;
	box-shadow: none;
	box-sizing: border-box;
	display: block;
	line-height: 1.75;
	outline: 0;
	padding: 1rem;
	resize: vertical;
	width: 100%;
}

.input:-webkit-autofill,
.acf-form input:-webkit-autofill,
.input:-webkit-autofill:focus,
.acf-form input:-webkit-autofill:focus,
.input:-webkit-autofill:hover,
.acf-form input:-webkit-autofill:hover,
.select:-webkit-autofill,
.acf-form select:-webkit-autofill,
.select:-webkit-autofill:focus,
.acf-form select:-webkit-autofill:focus,
.select:-webkit-autofill:hover,
.acf-form select:-webkit-autofill:hover,
.textarea:-webkit-autofill,
.acf-form textarea:-webkit-autofill,
.textarea:-webkit-autofill:focus,
.acf-form textarea:-webkit-autofill:focus,
.textarea:-webkit-autofill:hover,
.acf-form textarea:-webkit-autofill:hover {
	-webkit-box-shadow: 0 0 0 1000px var(--color-yellow) inset;
	-webkit-text-fill-color: black;
	border-color: var(--color-yellow);
}

.input:-webkit-autofill:focus,
.select:-webkit-autofill:focus,
.textarea:-webkit-autofill:focus {
	border-color: var(--color-grey);
}
