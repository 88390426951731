.l-error-page {
	background-color: var(--color-grey-light);
	color: blue;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 100vh;
}

.l-error-page__logo {
	display: block;
	margin: 0 0 2.5rem;
}

.l-error-page__logo__link {
	color: currentColor;
	display: block;
	margin-right: auto;
	margin-left: auto;
	width: 140px;
	height: 60px;
}

@media (--mq-from-large) {
	.l-error-page__logo__link {
		width: 164px;
		height: 70px;
	}
}

.l-error-page__logo__icon {
	display: block;
	fill: currentColor;
	transition: fill 96ms linear;
	width: 100%;
	height: 100%;
}

.l-error-page__logo__link:hover .l-error-page__logo__icon {
	fill: var(--color-red);
}

.l-error-page__content {
	text-align: center;
}
